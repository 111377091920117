import { useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component";
import Sheet from 'react-modal-sheet';
import GoogleNavigateItem from "../googlenavigate/GoogleNavigateItem";

export default function ExplorePlaceModal(
    { isExplorePlaceModalOpen, placeItem, onSaveToCollectionClick, onNavigateClick, onExplorePlaceModalDismiss }
) {

    const [isAddressCopied, setIsAddressCopied] = useState(false)

    function resetAddressCopyState() {
        setIsAddressCopied(false)
    }

    function carouselLeftButtonClick() {
        const carouselElement = document.getElementById("big-explore-place-carousel")
        let carouselWidth = carouselElement.clientWidth
        let scrollLeft = carouselElement.scrollLeft
        if (scrollLeft - carouselWidth >= 0) {
            carouselElement.scrollTo(scrollLeft - carouselWidth, 0)
        }
    }

    function carouselRightButtonClick() {
        const carouselElement = document.getElementById("big-explore-place-carousel")
        let carouselWidth = carouselElement.clientWidth
        let scrollLeft = carouselElement.scrollLeft
        carouselElement.scrollTo(scrollLeft + carouselWidth, 0)
    }


    function onThumbnailClick(clickedId) {
        console.log(`thumbnail click ${clickedId}`)
        const carouselElement = document.getElementById("big-explore-place-carousel")
        let carouselWidth = carouselElement.clientWidth
    
        let targetXPixel = (carouselWidth * clickedId) + 1
    
        carouselElement.scrollTo(targetXPixel, 0)
    }

    return <Sheet isOpen={isExplorePlaceModalOpen} onClose={() => {onExplorePlaceModalDismiss()}} onOpenEnd={() => {}} detent="content-height">
        <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
        <Sheet.Scroller draggableAt="both">
        <div className="pb-8 px-4 ">
            <div className="text-xl font-bold pt-8 md:px-8">{placeItem.name}</div>
            {
                placeItem.fullAddress &&
                <div className="flex pt-2 w-full text-sm self-center text-base-content md:px-8">
                    <span className="self-center pr-4">{placeItem.fullAddress}</span>
                    {
                        !isAddressCopied && <svg className="h-5 w-5 self-center" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                navigator.clipboard.writeText(placeItem.fullAddress)
                                setIsAddressCopied(true)
                                setTimeout(resetAddressCopyState, 1000)
                                // logEvent(analytics, AnalyticsEvent.DETAILS_COPY_ADDRESS_CLICK, {
                                //     place_id: placeDetails.id
                                // })
                            }
                        }>
                        <path className="fill-base-content" fill-rule="evenodd" clip-rule="evenodd" d="M21 8C21 6.34315 19.6569 5 18 5H10C8.34315 5 7 6.34315 7 8V20C7 21.6569 8.34315 23 10 23H18C19.6569 23 21 21.6569 21 20V8ZM19 8C19 7.44772 18.5523 7 18 7H10C9.44772 7 9 7.44772 9 8V20C9 20.5523 9.44772 21 10 21H18C18.5523 21 19 20.5523 19 20V8Z"/>
                        <path className="fill-base-content" d="M6 3H16C16.5523 3 17 2.55228 17 2C17 1.44772 16.5523 1 16 1H6C4.34315 1 3 2.34315 3 4V18C3 18.5523 3.44772 19 4 19C4.55228 19 5 18.5523 5 18V4C5 3.44772 5.44772 3 6 3Z" />
                        </svg>
                    }
                    {isAddressCopied && <svg className="h-5 w-5 self-center" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="stroke-base-content" d="M4 12.6111L8.92308 17.5L20 6.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    }
                </div>
            }
            {
                placeItem.score && <div className="pt-4 md:px-8">
                    {
                        GoogleNavigateItem({reviewScore: placeItem.score, onClick: (e) => { 
                            onNavigateClick()
                            // window.open(placeDetails.googlePlace.mapsLink, "_blank")
                            // logEvent(analytics, AnalyticsEvent.DETAILS_GOOGLE_NAVIGATE_CLICK, {
                            //     place_id: placeDetails.id
                            // })
                         }})
                    }
                </div>
            }
            <div className="grid grid-cols-1 md:grid-cols-2 mt-3 md:px-8 pb-20 min-h-[80vh] md:gap-4">
                <div className="row-span-2 md:row-span-1 md:order-last">
                    {
                        placeItem.images && placeItem.images.length > 0 && <div className="relative">
                            <div className="carousel md:rounded-lg h-96 carousel-center w-full overflow-y-hidden" id="big-explore-place-carousel">
                                {
                                    placeItem.images.map((image, index) => {
                                    return <div className="carousel-item w-full h-full" id={`full-carousel-${index}`} key={`full-carousel-${index}`}>
                                            <div className="w-full h-96">
                                                { image.attributionHtml && <LazyLoadImage className="object-cover w-full h-80 rounded-md" src={image.url} alt={image.attribution} onClick={() => {  }}/> }
                                                { !image.attributionHtml && <LazyLoadImage className="object-cover w-full h-96 rounded-md" src={image.url} alt={image.attribution} onClick={() => {  }} /> }
                                                { image.attributionHtml && <div className="h-16 mt-auto w-full px-2 pt-2 text-center text-xs" 
                                                dangerouslySetInnerHTML={{__html: image.attributionHtml.map((link) => `Credit: ${link.replace("<a ", "<a target=\"_blank\"")}`).join("")}}></div> }
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                            <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2 pointer-events-none">
                                <div className="btn btn-circle pointer-events-auto" onClick={() => carouselLeftButtonClick()}>❮</div> 
                                <div className="btn btn-circle pointer-events-auto" onClick={() => carouselRightButtonClick()}>❯</div>
                            </div>
                        </div>
                    }
                    {
                        placeItem.images.length > 0 && <div className="md:pr-4 pb-4">
                            {/* <input id="upload-image-input" type="file" accept="image/png, image/jpeg" className="file-input file-input-bordered w-full max-w-xs hidden" onChange={onFileInputChange}/> */}
                            <div id="place-images-container" className="carousel rounded-md h-24 w-full space-x-2">
                                {/* <div className="carousel-item w-2 rounded-md"></div> */}
                                <div className="carousel-item w-2 md:hidden"></div>
                            {
                                // !loadingText && placeImages.length > 0 && <div className="carousel-item">
                                //     <button className="btn btn-ghost h-24 w-24 p-0 rounded-md border-base-content border-dashed border-2 " onClick={() => {document.getElementById('upload-image-input').click();}}>
                                //     <div className="h-20 w-20 flex place-content-center items-center text-2xl font-bold rounded-md ">+</div>
                                //     </button>
                                // </div>
                            }
                            {
                                // !loadingText && placeImages.length == 0 && <div className="carousel-item">
                                //     <button className="btn btn-ghost h-24 w-32 p-0 rounded-md border-base-content border-dashed border-2 normal-case" onClick={() => {document.getElementById('upload-image-input').click();}}>
                                //     <div className="h-20 w-32 flex place-content-center items-center text-md font-bold rounded-md ">
                                //     <svg className="h-4 w-4 mr-1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path className="stroke-base-content group-hover:stroke-base-100" d="M14.2647 15.9377L12.5473 14.2346C11.758 13.4519 11.3633 13.0605 10.9089 12.9137C10.5092 12.7845 10.079 12.7845 9.67922 12.9137C9.22485 13.0605 8.83017 13.4519 8.04082 14.2346L4.04193 18.2622M14.2647 15.9377L14.606 15.5991C15.412 14.7999 15.8149 14.4003 16.2773 14.2545C16.6839 14.1262 17.1208 14.1312 17.5244 14.2688C17.9832 14.4253 18.3769 14.834 19.1642 15.6515L20 16.5001M14.2647 15.9377L18.22 19.9628M18.22 19.9628C17.8703 20 17.4213 20 16.8 20H7.2C6.07989 20 5.51984 20 5.09202 19.782C4.7157 19.5903 4.40973 19.2843 4.21799 18.908C4.12583 18.7271 4.07264 18.5226 4.04193 18.2622M18.22 19.9628C18.5007 19.9329 18.7175 19.8791 18.908 19.782C19.2843 19.5903 19.5903 19.2843 19.782 18.908C20 18.4802 20 17.9201 20 16.8V13M11 4H7.2C6.07989 4 5.51984 4 5.09202 4.21799C4.7157 4.40973 4.40973 4.71569 4.21799 5.09202C4 5.51984 4 6.0799 4 7.2V16.8C4 17.4466 4 17.9066 4.04193 18.2622M18 9V6M18 6V3M18 6H21M18 6H15" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg> Add images</div>
                                //     </button>
                                // </div>
                            }
                            {
                                placeItem.images.map((image, index) => {
                                    return <div className="carousel-item" id={`thumbnail-${index}`} key={`thumbnail-${index}`} onClick={() => { onThumbnailClick(index) }}>
                                        <LazyLoadImage className="h-24 w-24 object-cover rounded-md" src={image.url} />
                                    </div>
                                })
                            }<div className="carousel-item w-2 md:hidden"></div></div>
                        </div>
                    }
                </div>
                <div className="md:row-span-1">
                    {
                        <div className="mb-8">
                            <div className="grid grid-cols-[auto,1fr] md:rounded-md bg-base-200 mb-4 px-4 py-4 gap-y-2 gap-x-2 rounded-md">
                                <div className="grow text-base self-center text-base-content">
                                    {placeItem.summary}
                                </div>
                            </div>
                            { placeItem.summary != placeItem.consolidatedReview && <div className="grid grid-cols-[auto,1fr] md:rounded-md bg-base-200 mb-4 px-4 py-4 gap-y-2 gap-x-2 rounded-md">
                                <div className="grow text-base self-center text-base-content">
                                    <div className="text-lg font-bold pb-2">What others say</div>
                                    {placeItem.consolidatedReview}
                                </div>
                            </div>}
                        </div>
                    }
                    {/* {
                        placeDetails && placeDetails.nearby && <div className="grid grid-cols-[auto,1fr] md:rounded-md bg-base-200 md:mx-4 mb-4 px-4 py-4 gap-y-2 gap-x-2">
                            { placeDetails.nearby.metro && placeDetails.nearby.metro.length > 0 && <div className="font-bold pr-4">🚇 Metro</div> }
                            { placeDetails.nearby.metro && placeDetails.nearby.metro.length > 0 && <div>{ placeDetails.nearby.metro.join(", ") }</div> }
                            { placeDetails.nearby.landmarks && placeDetails.nearby.landmarks.length > 0 && <div className="font-bold pr-4">🏛️ Landmarks</div> }
                            { placeDetails.nearby.landmarks && placeDetails.nearby.landmarks.length > 0 && <div>{ placeDetails.nearby.landmarks.join(", ") }</div> }
                        </div>
                    }
                    {
                    placeDetails && placeDetails.staticMaps && <div className="px-4 pb-4">
                        <div className="h-52 md:h-52 rounded-md relative text-center">
                            <img className="object-cover w-full h-full rounded-md" src={isMapUsingDarkMode ? placeDetails.staticMaps.dark : placeDetails.staticMaps.light}/>
                            <div className="absolute bottom-4 w-full text-center">
                            <button className="btn btn-primary normal-case text-base" onClick={() => {
                                window.open(placeDetails.googlePlace.mapsLink)
                                logEvent(analytics, AnalyticsEvent.DETAILS_VIEW_IN_MAPS_CLICK, {
                                    place_id: placeDetails.id
                                })
                            }}>View in Maps</button>
                            </div>
                        </div>
                    </div>
                    } */}
                </div>
            </div>

        </div>

        </Sheet.Scroller>
        <div className="p-4 md:px-12 w-screen text-center bg-transparent flex gap-2 items-center justify-center">
            <button className="btn text-base btn-success normal-case rounded-lg flex-1 md:w-auto md:flex-none" onClick={() => { onSaveToCollectionClick() }}>
                <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="fill-success-content" fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9L11.25 11.25H9C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75H11.25V15C11.25 15.4142 11.5858 15.75 12 15.75C12.4142 15.75 12.75 15.4142 12.75 15L12.75 12.75H15C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H12.75V9Z"/>
                </svg> 
                Save to Collection
            </button>
            <button className="btn text-base btn-neutral normal-case rounded-lg flex-none" onClick={() => { onExplorePlaceModalDismiss() }}>
                Cancel
            </button>
        </div>

        </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={(_) => {
            onExplorePlaceModalDismiss()
        }}/>
    </Sheet>
}