import { createClient } from "@supabase/supabase-js";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import UserRepository from "../../repository/UserRepository";

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function LoginRedirectPage() {

    const [loadingText, setLoadingText] = useState(null)
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()


    useEffect(() => {
        const userRepository = new UserRepository()
  
        async function getUserSignUpStatus() {
          setLoadingText("Loading ...")
          const {
            data: { user },
          } = await supabase.auth.getUser()
          if (user == null) {
            navigate("/login", {replace: true})
          } else {
            const redirectCollectionId = searchParams.get("collectionId")
            const userSignUpStatus = await userRepository.getUserSignUpStatus()
            if (userSignUpStatus.isProfileComplete) {
                if (redirectCollectionId) {
                  navigate(`/collections/${redirectCollectionId}`, { replace: true })
                } else {
                  navigate("/", {replace: true})
                }
            } else {
                if (redirectCollectionId) {
                  navigate(`/signup/completion?collectionId=${redirectCollectionId}`, {replace: true})
                } else {
                  navigate(`/signup/completion`, {replace: true})
                }
            }
          }
        }

        getUserSignUpStatus()
  
      }, [])

    return (
        <div>
          <div className="navbar bg-base-100">
              <div className="flex-1 pl-2">
                  <img className="w-8 h-8" src="https://oesctluxvegwtcqckcdg.supabase.co/storage/v1/object/public/cooray-shared-resources/roambear_logo.png"/>
                  <div className="text-xl font-bold px-2">Roambear</div>
              </div>
              </div>
              {loadingText &&
                <div className='col-span-3 md:col-span-6 flex flex-col items-center justify-center pt-4'>
                    <div className="loading loading-infinity loading-lg"></div>
                    <div className='font-normal text-base-content mt-2'>{loadingText}</div>
                </div>
                }
        </div>
    )
}
