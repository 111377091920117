import FilterChipsContainer from "../FilterChipsContainer"
import { useState, useEffect, useRef } from "react"
import Sheet from 'react-modal-sheet';

export default function ExploreFilterModal(
    { isFilterModalOpen, filters, selectedFilterOptions, onApplyClick, onFilterModalDismiss }
) {

    const footerRef = useRef(null)
    const [enabledFilters, setEnabledFilters] = useState(selectedFilterOptions)
  
    useEffect( () => {
        setEnabledFilters(selectedFilterOptions)
    }, [selectedFilterOptions])

    function onFilterOptionToggled(clickedOption) {
        console.log(`filter option toggled ${JSON.stringify(clickedOption)}`)
        if (enabledFilters.includes(clickedOption.id)) {
            setEnabledFilters(enabledFilters.filter((option) => option != clickedOption.id))
        } else {
            setEnabledFilters(enabledFilters.concat([clickedOption.id]))
        }
    }

    return <Sheet isOpen={isFilterModalOpen} onClose={() => {onFilterModalDismiss()}} onOpenEnd={() => {
    }} detent="content-height">
        <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
        <Sheet.Scroller draggableAt="both">
            <div className="p-0">
        <div className={`px-6`}>
            <div className="h-8"></div>
        {
            <div className="mb-4">
            <h3 className="font-bold text-lg">🎛️ Filters</h3>
            <div className="pt-4">
                <FilterChipsContainer 
                    key={"food"}
                    title={null}
                    filterOptions={filters.map((filterOption) => {
                        return {
                            label: filterOption.displayLabel,
                            id: filterOption.tag,
                            isSelected: enabledFilters.includes(filterOption.tag)
                        }
                    })}
                    onFilterOptionClick={onFilterOptionToggled}
                    />
            </div>
        </div>
        }
        <div className="h-24"></div>
        </div>
        <div className="modal-action absolute bottom-0 right-0 bg-base-100 w-full px-6 py-4" ref={footerRef}>
            <button className="btn" onClick={
                () => {
                    setEnabledFilters([])
                    onApplyClick([] )
                    onFilterModalDismiss()
                }
                }>Clear</button>
            <button className="btn btn-success text-success-content mr-2" onClick={
                () => {
                    onApplyClick(enabledFilters)
                    onFilterModalDismiss()
                }
                } >Apply
            </button>
            <button className="btn btn-ghost" onClick={
                () => {
                    setEnabledFilters(selectedFilterOptions)
                    // document.getElementById(modalId).close()
                    onFilterModalDismiss()
                }
                }>Close</button>
        </div>
        </div>
        </Sheet.Scroller>
        </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={(_) => {
            onFilterModalDismiss()
        }}/>
    </Sheet>
}