import Sheet from 'react-modal-sheet';
import { useState } from "react";
import AddFeedbackModalContent from './AddFeedbackModalContent';
import FeedbackRepository from '../../../repository/FeedbackRepository';

export default function FeedbackButton({modifier = ""}) {

    const [isSavingFeedback, setIsSavingFeedback] = useState(false)
    const [isFeedbackBottomSheetOpen, setIsFeedbackBottomSheetOpen] = useState(false);
    const feedbackRepository = new FeedbackRepository()

    async function onSaveClick(feedback) {
        if (feedback) {
            setIsSavingFeedback(true)
            await feedbackRepository.saveFeedback(feedback)
            setIsSavingFeedback(false)
            setIsFeedbackBottomSheetOpen(false)
            document.getElementById(`feedback-message-modal`).showModal()
        }
    }

    return (
        <div className={`fixed bottom-0 left-0 ${modifier}`}>
            <button className="btn btn-xs btn-neutral rounded-b-none ml-4 normal-case pl-4 pr-4" onClick={() => {setIsFeedbackBottomSheetOpen(true)}}>Feedback</button>
            {
                <Sheet isOpen={isFeedbackBottomSheetOpen} onClose={() => {setIsFeedbackBottomSheetOpen(false)}} onOpenEnd={() => {
                }}>
                    <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content>
                        <AddFeedbackModalContent
                            isSavingFeedback={isSavingFeedback}
                            onSaveClick={onSaveClick}
                            onCancelClick={() => setIsFeedbackBottomSheetOpen(false) }
                        />
                    </Sheet.Content>
                    </Sheet.Container>
                    <Sheet.Backdrop onTap={(_) => {
                        setIsFeedbackBottomSheetOpen(false)
                    }}/>
                </Sheet>
            }
            {
                <dialog id="feedback-message-modal" className="modal">
                    <div className="modal-box w-11/12 max-w-5xl">
                        <p className="py-2 text-base">Thank you for your feedback!</p>
                        <div className="modal-action">
                            <button className="btn btn-ghost" onClick={() => { document.getElementById("feedback-message-modal").close()}}>You're welcome</button>
                        </div>
                    </div>
                </dialog>
            }
        </div>
    )
}
