import { useEffect, useState } from "react";
import GoogleRepository from "../../../repository/GoogleRepository";
import AutocompleteInput from "../AutocompleteInput";

export default function ExploreListSearchInput({ appliedPlace, onPlaceSelected }) {

    const [searchTerm, setSearchTerm] = useState('')
    const [searchPredictions, setSearchPredictions] = useState([])
    const [autocompleteLoadingText, setAutocompleteLoadingText] = useState(null)
    const [selectedPlace, setSelectedPlace] = useState(appliedPlace)

    useEffect(() => {
      const googleRepository = new GoogleRepository()
      async function getPlacesAutocomplete(input) {
        const places = await googleRepository.getPlacesAutocomplete(input)
        console.log(places)
        setSearchPredictions(
          places.predictions.map((prediction) => {
            return {
              label: prediction.structured_formatting.main_text,
              secondaryLabel: prediction.structured_formatting.secondary_text,
              key: prediction.place_id,
              link: "#"
            }
          })
        )
        setAutocompleteLoadingText(null)
      }
  
      const delayDebounceFn = setTimeout(() => {
        console.log(searchTerm)
        if (searchTerm) {
          getPlacesAutocomplete(searchTerm)
        }
      }, 1500)
  
      return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    function handlePlaceInputChange(event) {
        setSelectedPlace(null)
        setSearchPredictions([])
        const searchString = event.target.value
        console.log(`searchString is ${searchString}`)
        if (searchString) {
            setAutocompleteLoadingText("Searching ...")
            setSearchTerm(searchString)
        } else {
            setSearchTerm(null)
        }
    }

    function handlePlaceItemSelected(item) {
        console.log(`handle selected ${item}`)
        console.log(item)
        setSelectedPlace(item)
        setSearchPredictions([])
        onPlaceSelected(item)
      }

    return <div className="form-control w-full px-4">
        <label className="label pb-4">
        <span className="label-text text-base">Enter a location to sort explore places by distance from this location</span>
        </label>
        <AutocompleteInput 
            placeholder={"Enter a location, e.g. Raffles Place ..."}
            onValueChange={handlePlaceInputChange}
            items={ searchPredictions }
            value={ selectedPlace?.label ?? searchTerm }
            onItemSelected={handlePlaceItemSelected}
            loadingText={autocompleteLoadingText}
            />
    </div>
}