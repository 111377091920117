import { createClient } from "@supabase/supabase-js";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sheet from 'react-modal-sheet';
import ExploreRepository from "../../repository/ExploreRepository";
import ExploreListItem from "../../shared/components/exploreplaces/ExploreListItem";
import CoorayAppBar, { NavigationButtonType } from "../../shared/components/CoorayAppBar";
import CurationPicker from "../curationpicker/CurationPicker";
import PlaceRepository from "../../repository/PlaceRepository";
import { useRef } from "react";
import ExplorePlaceModal from "../../shared/components/exploreplacemodal/ExplorePlaceModal";
import ExploreListFilterBar from "../../shared/components/exploreplaces/ExploreListFilterBar";
import ExploreFilterModal from "../../shared/components/explorefiltermodal/ExploreFilterModal";
import ExploreListSearchInput from "../../shared/components/exploreplaces/ExploreListSearchInput";

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function ExplorePage({analytics, onRequestToScrollToTop}) {

    const [loadingText, setLoadingText] = useState(null)
    const [overlayLoadingText, setOverlayLoadingText] = useState(null)
    const [isShowingCurationPicker, setIsShowingCurationPicker] = useState(false)
    const [isExplorePlaceModalOpen, setIsExplorePlaceModalOpen] = useState(false)
    const [explorePlaces, setExplorePlaces] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [pendingSaveExplorePlace, setPendingSaveExplorePlace] = useState(null)
    const [toastMessage, setToastMessage] = useState(null)
    const [currentOffset, setCurrentOffset] = useState(0)
    const [currentSeed, setCurrentSeed] = useState(null)
    const [viewingExplorePlace, setViewingExplorePlace] = useState(null)
    const [selectedPlace, setSelectedPlace] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            setToastMessage(null)
          }, 2000);
    }, [toastMessage])

    useEffect(() => {
        getExplorePlacesByTags()
    }, [])

    async function getExplorePlacesByTags(shouldReset = false, newlySelectedTags = null, placeCenter = null) {
        setLoadingText("Loading ...")
        if (shouldReset) {
            setExplorePlaces([])
        }
        if (newlySelectedTags) {
            setSelectedTags(newlySelectedTags)
        }
        if (placeCenter) {
            setSelectedPlace(placeCenter)
        }
        const {
            data: { user },
        } = await supabase.auth.getUser()
        if (user == null) {
            navigate("/login", {replace: true})
        } else {
            const exploreRepository = new ExploreRepository()
            try {
                const googlePlaceId = placeCenter || selectedPlace ? (placeCenter ?? selectedPlace).key : null
                const tags = newlySelectedTags ?? selectedTags
                const seed = shouldReset ? null : currentSeed
                const offset = shouldReset ? 0 : currentOffset
                const existing = shouldReset ? [] : explorePlaces
                const explorePlacesResponse = await exploreRepository.getExplorePlaces(offset, seed, tags, googlePlaceId)
                
                setSelectedTags(tags)
                if (placeCenter) {
                    setSelectedPlace(placeCenter)
                }

                setExplorePlaces(
                    existing.concat(
                        explorePlacesResponse.places.map((place) => {
                            return {
                                ...place, 
                                score: explorePlacesResponse.googlePlaceIdToGoogleReviewScore[place.googlePlaceId],
                                fullAddress: explorePlacesResponse.googlePlaceIdToFullAddress[place.googlePlaceId],
                                consolidatedReview: explorePlacesResponse.googlePlaceIdToConsolidatedReview[place.googlePlaceId] ?? place.summary ,
                                images: explorePlacesResponse.googlePlaceIdToImages[place.googlePlaceId],
                                reviewScore: explorePlacesResponse.googlePlaceIdToGoogleReviewScore[place.googlePlaceId],
                                mapLink: explorePlacesResponse.googlePlaceIdToMapsUrl[place.googlePlaceId]
                            }
                        })
                    )
                )
                setCurrentOffset(explorePlacesResponse.offset)
                setCurrentSeed(explorePlacesResponse.seed)

                setLoadingText(null)
            } catch(e) {
                console.log(`get explore places error ${e.response.status}`)
            }
        }
    }

    async function saveExplorePlaceToCuration(googlePlaceId, curationId) {
        setOverlayLoadingText("Saving ...")
        const {
            data: { user },
        } = await supabase.auth.getUser()
        if (user == null) {
            navigate("/login", {replace: true})
        } else {
            const placeRepository = new PlaceRepository()
            try {
                await placeRepository.savePlace(curationId, googlePlaceId, [])
                setOverlayLoadingText(null)
                setToastMessage("Place saved to collection!")
            } catch(e) {
                console.log(`get explore places error ${e.response.status}`)
            }
        }
    }

    function onLoadMoreClick() {
        getExplorePlacesByTags()
    }

    return (
        <div className="bg-base-100">
            {
                <div className={`sticky col-span-3 md:col-span-6 top-16 z-20 relative`}>
                    <ExploreListFilterBar 
                    selectedTags={selectedTags}
                    appliedPlace={selectedPlace}
                    onTagPress={(_) => {}}
                    onTagsApplied={(options) => {
                        getExplorePlacesByTags(true, options, null)
                        onRequestToScrollToTop()
                    }}
                    onLocationApplied={(place) => {
                        getExplorePlacesByTags(true, null, place)
                        onRequestToScrollToTop()
                    }}
                    onMainFilterButtonClick={() => {
                        
                    }}
                    />
                </div>
            }
            <div className="grid grid-cols-3 md:grid-cols-6 gap-4 md:px-4 pb-20 items-stretch place-items-stretch">
                {
                    explorePlaces.map((place) => {
                        return <div className="col-span-3" key={place.googlePlaceId}><ExploreListItem
                            placeItem={place}
                            onAddClick={() => { 
                                setPendingSaveExplorePlace(place)
                                setIsShowingCurationPicker(true) 
                            }}
                            clickEvent={() => {
                                setViewingExplorePlace(place)
                                setIsExplorePlaceModalOpen(true)
                            }}
                        /></div>
                    })
                }
                {loadingText &&
                <div className='col-span-3 md:col-span-6 flex flex-col items-center justify-center pt-4'>
                    <div className="loading loading-infinity loading-lg"></div>
                    <div className='font-normal text-base-content mt-2'>{loadingText}</div>
                </div>
                }
                { !loadingText && <div className='col-span-3 md:col-span-6 flex flex-col items-center justify-center'>
                    <button className="btn btn-secondary normal-case text-base" onClick={() => { onLoadMoreClick() }}>Load more</button></div> }
            </div>
            {overlayLoadingText &&
            <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
                <div className="loading loading-infinity loading-lg"></div>
                <div className='font-normal text-base-content mt-2'>{overlayLoadingText}</div>
            </div>
            }
            {
                toastMessage && <div className="toast z-10">
                <div className="alert alert-info">
                  <span className="text-sm">{toastMessage}</span>
                </div>
              </div>
            }
            {
                isExplorePlaceModalOpen && viewingExplorePlace && <ExplorePlaceModal 
                    isExplorePlaceModalOpen={isExplorePlaceModalOpen}
                    placeItem={viewingExplorePlace}
                    onExplorePlaceModalDismiss={() => {
                        setIsExplorePlaceModalOpen(false)
                    }}
                    onSaveToCollectionClick={() => {
                        setIsExplorePlaceModalOpen(false)
                        setPendingSaveExplorePlace(viewingExplorePlace)
                        setIsShowingCurationPicker(true) 
                    }}
                    onNavigateClick={() => {
                        window.open(viewingExplorePlace.mapLink, "_blank")
                    }}
                />
            }
            {
                <Sheet isOpen={isShowingCurationPicker} onClose={() => {setIsShowingCurationPicker(false)}} onOpenEnd={() => {}} detent="content-height">
                <Sheet.Container>
                <Sheet.Header />
                <Sheet.Content>
                <CoorayAppBar 
                title={ "Pick a collection" }
                onBackPressed={() => {
                    setIsShowingCurationPicker(false)  
                }}
                navigationType={NavigationButtonType.Close}
                />
                <Sheet.Scroller draggableAt="both">
                    <CurationPicker
                    shouldShowTitleAppBar={false}
                    analytics={analytics}
                    onPickerBackPressed={() => {}}
                    onPickerCurationSelected={(curationId, curationName) => {
                        // onAddToAnotherCollectionSelected(curationId, curationName)
                        // getCurations(curationId)
                        if (pendingSaveExplorePlace) {
                            setIsShowingCurationPicker(false)
                            saveExplorePlaceToCuration(pendingSaveExplorePlace.googlePlaceId, curationId)
                        }
                    }}
                />
                </Sheet.Scroller>
                </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop onTap={(_) => {
                    setIsShowingCurationPicker(false)
                }}/>
            </Sheet>
            }
        </div>
    )
}
