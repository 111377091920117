import React, { createContext, useState, useContext } from 'react';

const YourCurationsContext = createContext();

export const useYourCurationsContext = () => {
  return useContext(YourCurationsContext);
};

export const YourCurationsProvider = ({ children }) => {
  const [combinedCurations, setCombinedCurations] = useState([]);

  return (
    <YourCurationsContext.Provider value={{ combinedCurations, setCombinedCurations }}>
      {children}
    </YourCurationsContext.Provider>
  );
};