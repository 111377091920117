import Lottie from "lottie-react"
import lottieSuccess from '../../lottie/lottie-success.json';
import { useState } from "react"
import Sheet from 'react-modal-sheet';

export default function CollectionWelcomeModal(
    { isWelcomeModalOpen, collectionName, totalPlaceNumber,onWelcomeModalDismiss }
) {
    return <Sheet isOpen={isWelcomeModalOpen} onClose={() => {onWelcomeModalDismiss()}} onOpenEnd={() => {}} detent="content-height">
        <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
        <Sheet.Scroller draggableAt="both">
        <div className="pb-4 px-4">
        <div className="flex flex-col w-full items-center justify-center px-4">
            <Lottie className="w-36 h-36" animationData={lottieSuccess} loop={true} />
            <div className="font-normal text-lg text-base-content text-center px-4">
                Welcome to <span className="font-bold">{collectionName}</span>! Glad to have you here. 🙌🏼  
            </div>
            <div className="font-normal text-base text-base-content text-center px-4">
                😎 Check out the {totalPlaceNumber} spots we've got, or add your own anytime!
            </div>
            <div className="font-normal text-base text-base-content text-center px-4">
                🎙️ Drop a comment on any place to chat with others. Have fun!
            </div>
        </div>
        <div className="flex w-full justify-center py-4">
            <button className="btn btn-active btn-primary" onClick={() => {onWelcomeModalDismiss()}}>
                    View Collection
                    </button>
            </div>
        </div>
        </Sheet.Scroller>
        </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={(_) => {
            onWelcomeModalDismiss()
        }}/>
    </Sheet>
}