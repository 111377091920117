import Sheet from 'react-modal-sheet';

export default function GeneratedInvitationLinkModal(
    { isInvitationLinkModalOpen, url, onGeneratedInvitationLinkDismiss, onCopyLinkClick, onShareLinkClick }
) {
    return <Sheet isOpen={isInvitationLinkModalOpen} onClose={() => {onGeneratedInvitationLinkDismiss()}} onOpenEnd={() => {}} detent="content-height">
        <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
        <Sheet.Scroller draggableAt="both">
        <div className="pt-4 pb-16 px-4">
        <div className="flex flex-col w-full items-start place-content-start px-4">
            <div className="font-normal text-lg text-base-content text-center pb-4">
                Generated invitation link:
            </div>
            <div className="font-normal text-base text-base-content text-start p-4 flex flex-row rounded-lg bg-base-200">
                <span className="flex-1 max-w-full break-all">{url}</span>
            </div>
            <div className='flex flex-row w-full gap-2'>
            <button className="btn btn-secondary normal-case flex-1 text-base md:w-auto mt-4" onClick={() => {
                onCopyLinkClick()
            } }>
                <svg className='h-6 w-6' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className='fill-secondary-content' fill-rule="evenodd" clip-rule="evenodd" d="M21 8C21 6.34315 19.6569 5 18 5H10C8.34315 5 7 6.34315 7 8V20C7 21.6569 8.34315 23 10 23H18C19.6569 23 21 21.6569 21 20V8ZM19 8C19 7.44772 18.5523 7 18 7H10C9.44772 7 9 7.44772 9 8V20C9 20.5523 9.44772 21 10 21H18C18.5523 21 19 20.5523 19 20V8Z"/>
                <path className='fill-secondary-content' d="M6 3H16C16.5523 3 17 2.55228 17 2C17 1.44772 16.5523 1 16 1H6C4.34315 1 3 2.34315 3 4V18C3 18.5523 3.44772 19 4 19C4.55228 19 5 18.5523 5 18V4C5 3.44772 5.44772 3 6 3Z"/>
                </svg>
                Copy Link
            </button>
            <button className="btn btn-primary normal-case flex-1 text-base md:w-auto mt-4" onClick={() => {
                onShareLinkClick()
            } }>
                <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 26 26" className='w-6 h-6'><path className='fill-primary-content' d="M 21 0 C 18.238281 0 16 2.238281 16 5 C 16 5.085938 16.027344 5.164063 16.03125 5.25 L 8.1875 9.1875 C 7.320313 8.457031 6.222656 8 5 8 C 2.238281 8 0 10.238281 0 13 C 0 15.761719 2.238281 18 5 18 C 6.222656 18 7.320313 17.542969 8.1875 16.8125 L 16.03125 20.75 C 16.027344 20.835938 16 20.914063 16 21 C 16 23.761719 18.238281 26 21 26 C 23.761719 26 26 23.761719 26 21 C 26 18.238281 23.761719 16 21 16 C 19.777344 16 18.679688 16.457031 17.8125 17.1875 L 9.96875 13.25 C 9.972656 13.164063 10 13.085938 10 13 C 10 12.914063 9.972656 12.835938 9.96875 12.75 L 17.8125 8.8125 C 18.679688 9.542969 19.777344 10 21 10 C 23.761719 10 26 7.761719 26 5 C 26 2.238281 23.761719 0 21 0 Z"/></svg>
                Share Link
            </button>
            </div>
        </div>
        </div>
        </Sheet.Scroller>
        </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={(_) => {
            onGeneratedInvitationLinkDismiss()
        }}/>
    </Sheet>
}