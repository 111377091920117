import { useState, useEffect, memo, useRef } from 'react'
import { createClient } from '@supabase/supabase-js'
import CoorayAppBar, { NavigationButtonType } from '../../shared/components/CoorayAppBar';
import { useParams, useSearchParams } from 'react-router-dom';
import CurationRepository from '../../repository/CurationRepository';
import PlaceRepository from '../../repository/PlaceRepository';
import CurationListFilterBar from '../../shared/components/CurationListFilterBar';
import CurationListItem from '../../shared/components/curationitem/CurationListItem';
import FilterModal from '../../shared/components/filtermodal/FilterModal';
import { useNavigate } from 'react-router-dom';
import PlaceDetailsPage from '../placedetails/PlaceDetailsPage';
import { CurationMap } from './CurationMap';
import SortModal from '../../shared/components/sortmodal/SortModal';
import CoorayFooter from '../../shared/components/footer/CoorayFooter';
import { logEvent } from 'firebase/analytics';
import { AnalyticsEvent } from '../../shared/analytics/AnalyticsEvent';
import { Helmet } from 'react-helmet';
import MembersPage from '../member/MembersPage';
import { usePlaceDetailsContext } from '../placedetails/PlaceDetailsContext';
import { useCurationContext } from './CurationContext';
import { useCurationPlacesContext } from './CurationPlacesContext';
import { useCurationScrollStateContext } from './CurationScrollStateContext';
import { useCurationMembersContext } from './CurationMembersContext';
import { getPlaces, getUserLocation } from './CurationListUtils';
import { useCurationMapContext, useCurationMapHoverContext } from './CurationMapContext';
import PlaceSuggestionItem from '../../shared/components/curationitem/PlaceSuggestionItem';
import FeedbackButton from '../../shared/components/feedback/FeedbackButton';
import SearchModal from '../../shared/components/searchmodal/SearchModal';
import CollectionWelcomeModal from '../../shared/components/collectionwelcomemodal/CollectionWelcomeModal';
import GeneratedInvitationLinkModal from '../../shared/components/invitationlinkmodal/GeneratedInvitationLinkModal';

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

function filtersToFilterOptionsMap(filters) {
  const filterOptionMap = {}
  filters
  .flatMap(
    (filter) => filter.filterOptions
    .map(
      (filterOption) => {
        filterOption.filter = {
          id: filter.id,
          label: filter.label
        }
        return filterOption
      }
    )
  )
  .forEach((filterOption) => {
    filterOptionMap[filterOption.id] = filterOption
  })
  return filterOptionMap
}

export default function CurationPage({analytics}) {
  const navigate = useNavigate()
  const topRef = useRef()
  const mainListRef = useRef()
  const titleRef = useRef()
  const mapRef = useRef()
  const mapButtonRef = useRef()
  const {_, setPlaceDetails} = usePlaceDetailsContext()
  const {placesData, setPlacesData} = useCurationPlacesContext()
  const [placeSuggestions, setPlaceSuggestions] = useState([])
  const {curationMapConfig, setCurationMapConfig} = useCurationMapContext()
  const [currentPage, setCurrentPage] = useState(0)
  const {curationScrollState, setCurationScrollState} = useCurationScrollStateContext()
  const [isShowingList, setIsShowingList] = useState(curationScrollState.isShowingList)
  const [appBarDropdownItems, setAppBarDropdownItems] = useState([])
  const [isTitleVisible, setIsTitleVisible] = useState(true)
  const {curationMemberResponse, setCurationMemberResponse} = useCurationMembersContext()
  const {curationHoverId, setCurationHoverId} = useCurationMapHoverContext()
  const {curationData, setCurationData} = useCurationContext()
  const [isListLoading, setIsListLoading] = useState(false)
  const [fullScreenLoadingText, setFullScreenLoadingText] = useState(null)
  const [isDesktopView, setIsDesktopView] = useState(false)
  const [isWelcomeToCollectionBottomSheetOpen, setIsWelcomeToCollectionBottomSheetOpen] = useState(false)
  const [isFilterBottomSheetOpen, setIsFilterBottomSheetOpen] = useState(false)
  const [isSortBottomSheetOpen, setIsSortBottomSheetOpen] = useState(false)
  const [isSearchBottomSheetOpen, setIsSearchBottomSheetOpen] = useState(false)
  const [generatedInvitationTitle, setGeneratedInvitationTitle] = useState(null)
  const [generatedInvitationLink, setGeneratedInvitationLink] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const { curationId }= useParams()

  useEffect(() => {

    const curationRepository = new CurationRepository()

    async function getCurations(curationId, filterOptions, currentFilterDistance, filterVisitOptions, currentSortType, searchInput) {
      console.log(`getCurations ${curationData.curation.id} ${placesData.places.length}`)
      const {
        data: { user },
      } = await supabase.auth.getUser()
      if (user == null) {
        navigate("/login", {replace: true})
      } else {
        if (curationData.curation.id != curationId || curationData.curation.id == 0 || placesData.places.length == 0) {
          setIsListLoading(true)
          // let currentSortType = placesData.selectedSortType
            // let currentFilterDistance = placesData.selectedFilterDistance
            let coordinates = null
            if (currentSortType == "distance" || currentFilterDistance != null) {
              setFullScreenLoadingText("Fetching location ...")
              coordinates = await getUserLocation()
            }
            setFullScreenLoadingText(null)
            if (coordinates == null && currentSortType == "distance") {
              currentSortType = "recommended"
              // setSelectedSortType("last_updated")
            } else if (coordinates == null && placesData.selectedFilterDistance != null) {
              currentFilterDistance = null
              // setSelectedSortType("last_updated")
            }

            console.log(`search params check ${placesData.selectedFilterOptions}`)
            try {
              let [curationResponse, placesResponse] = await Promise.all([
                curationRepository.getCurations(curationId),
                getPlaces(supabase, curationId, {}, filterOptions, currentSortType, coordinates, currentFilterDistance, filterVisitOptions)
              ]);
              const placeList = placesResponse.places

              setCurationData(curationResponse)
              console.log(placeList)
              setPlacesData(
                {
                  places: placeList,
                  selectedSortType: currentSortType,
                  selectedFilterOptions: filterOptions,
                  selectedFilterDistance: currentFilterDistance,
                  selectedVisitOptions: filterVisitOptions,
                  selectedSearchInput: searchInput,
                  totalPlaceCount: placesResponse.totalPlaceCount,
                  isPlaceListSneakPeek: placesResponse.isPlaceListSneakPeek
                }
              )
              setIsListLoading(false)
              if (user.id == curationResponse.curation.authorUserId) {
                setAppBarDropdownItems([
                  {
                    label: "Edit collection",
                    callback: () => {
                      navigate(`/collections/${curationId}/edit`)
                    }
                  },
                  {
                    label: "Delete collection",
                    callback: () => {
                      console.log("delete collection callback")
                      logEvent(analytics, AnalyticsEvent.DELETE_CURATION_CLICK, {
                        curation_id: curationId 
                      })
                      document.getElementById(`confirm-deletion-modal`).showModal()
                    }
                  }
                ])
              } else {
                setAppBarDropdownItems([])
              }
            } catch (e) {
              console.log(e.response.status)
              if (e.response.status == 403) {
                navigate("/", {replace: true})
              }
            }
        } else {
          if (user.id == curationData.curation.authorUserId) {
            setAppBarDropdownItems([
              {
                label: "Edit collection",
                link: `/collections/${curationId}/edit`
              },
              {
                label: "Delete collection",
                callback: () => {
                  console.log("delete collection callback")
                  logEvent(analytics, AnalyticsEvent.DELETE_CURATION_CLICK, {
                    curation_id: curationId 
                  })
                  document.getElementById(`confirm-deletion-modal`).showModal()
                }
              }
            ])
          } else {
            setAppBarDropdownItems([])
          }
        }
      }
    }

    async function getPlaceSuggestions(curationId) {
      const curationRepository = new CurationRepository()
      const placeSuggestionResponse = await curationRepository.getCurationPlaceSuggestions(curationId)
      console.log(placeSuggestionResponse)
      setPlaceSuggestions(placeSuggestionResponse.suggestions.map((suggestion) => {
        return {
          ...suggestion, images: placeSuggestionResponse.images[suggestion.id]
        }
      }))
    }

    let existingFilters
    let existingFilterDistance
    const existingFilterStr = searchParams.get("filters")
    const existingDistanceFilterStr = searchParams.get("filterDistance")
    const isVisitedStr = searchParams.get("isOnlyVisited")
    const isNonVisitedStr = searchParams.get("isOnlyNotVisited")
    const sort = searchParams.get("sort")
    const searchInputStr = searchParams.get("searchInput")
    if (existingFilterStr) {
      existingFilters = existingFilterStr.split(",").map((filterStr) => parseInt(filterStr))
      console.log(`setting places filter ${JSON.stringify(existingFilters)}`)
    } else {
      existingFilters = placesData.selectedFilterOptions
    }

    if (existingDistanceFilterStr) {
      existingFilterDistance = parseFloat(existingDistanceFilterStr)
    } else {
      existingFilterDistance = placesData.selectedFilterDistance
    }

    const filterVisitOptions = {
      visited: isVisitedStr == "true",
      nonVisited: isNonVisitedStr == "true"
    }

    const currentSortType = sort ? sort : placesData.selectedSortType

    setPlacesData({
      ...placesData, 
      selectedFilterOptions: existingFilters, 
      selectedFilterDistance: existingFilterDistance,
      selectedVisitOptions: filterVisitOptions,
      selectedSortType: currentSortType,
      selectedSearchInput: searchInputStr
    })
    console.log(`search params existing ${JSON.stringify(existingFilters)} ${existingFilterDistance}`)

    Promise.all([
      getCurations(curationId, existingFilters, existingFilterDistance, filterVisitOptions, currentSortType, searchInputStr),
      getCurationMembers(curationId),
      // getPlaceSuggestions(curationId)
    ])
    logEvent(analytics, AnalyticsEvent.CURATION_VIEW, {
      curation_id: curationId
    })
    console.log(`previous scroll state ${curationScrollState.scrollPosition}`)
    console.log(`search params ${JSON.stringify(searchParams.get("filters"))}`)
    mainListRef.current.scrollTop = curationScrollState.scrollPosition
  }, [curationId])

  useEffect(() => {
    const currentIsDesktopView = mapButtonRef.current == null || mapButtonRef.current == undefined || getComputedStyle(mapButtonRef.current).display == "none"
    setIsDesktopView(currentIsDesktopView)
    if (currentIsDesktopView) {
      setIsShowingList(true)
    }
  }, [mapButtonRef.current, curationData])

  const handleScroll = () => {
    const currentScrollPos = mainListRef.current.scrollTop
    setIsTitleVisible(currentScrollPos < 64)
  }

  useEffect( () => {
      mainListRef.current.addEventListener('scroll', handleScroll);
      const handleResize = () => {
        const currentIsDesktopView = mapButtonRef.current == null || mapButtonRef.current == undefined || getComputedStyle(mapButtonRef.current).display == "none"
        console.log(`screen resize ${currentIsDesktopView}`)
        setIsDesktopView(currentIsDesktopView)
        if (currentIsDesktopView) {
          setIsShowingList(true)
        }
      }
      window.addEventListener("resize", handleResize, false);

  })

  async function getCurationMembers(curationId) {
    if (!curationMemberResponse || curationMemberResponse.curationId != curationId) {
      const curationRepository = new CurationRepository()
      const memberResponse = await curationRepository.getCurationMembers(curationId)
      console.log(memberResponse)
      setCurationMemberResponse({
        ...memberResponse, curationId: curationId
      })
    }
  }

  async function deleteCuration() {
    document.getElementById(`confirm-deletion-modal`).close()
    setFullScreenLoadingText("Deleting ...")
    const curationRepository = new CurationRepository()
    await curationRepository.deleteCuration(curationId)
    navigate("/")
  }

  async function generateInvitationLink(curationId) {
    if (!curationData.curation.isPrivate && curationData.curation.url) {
      setGeneratedInvitationLink(curationData.curation.url)
      setGeneratedInvitationTitle(curationData.curation.title)
    } else {
      setFullScreenLoadingText("Generating invitation link ...")
      const curationRepository = new CurationRepository()
      const response = await curationRepository.generateInvitationLink(curationId)
      console.log(JSON.stringify(response))
      setGeneratedInvitationLink(response.invitationLink)
      setGeneratedInvitationTitle(response.invitationTitle)
      setFullScreenLoadingText(null)
    }
  }

  async function removeVote(placeId, item) {
    const placeRepository = new PlaceRepository()
    placeRepository.removePlaceVote(placeId)
    let newUpvotes = item.upvotes
    let newDownvotes = item.downvotes
    if (item.userVote.type == "UPVOTE") {
      newUpvotes--
    } else {
      newDownvotes--
    }
    const updatedPlacesData = {
      ...placesData, places: placesData.places.map((place) => {
        if (place.id == placeId) {
            return {
              ...place, userVote: null, upvotes: newUpvotes, downvotes: newDownvotes
            }
        } else {
          return place
        }
      })
    }
    setPlacesData(updatedPlacesData)
  }

  async function joinPublicCuration(curationId) {
    setFullScreenLoadingText("Joining collection ...")
    const curationRepository = new CurationRepository()
    await curationRepository.joinPublicCuration(curationId)
    setFullScreenLoadingText(null)
    setIsWelcomeToCollectionBottomSheetOpen(true)
  }

  async function onUpvoteClick(placeId) {
    const targetItem = placesData.places.find(item => item.id == placeId)
    if (targetItem.userVote && targetItem.userVote.type == "UPVOTE") {
        removeVote(placeId, targetItem)
    } else {
      const placeRepository = new PlaceRepository()
      placeRepository.upvotePlace(placeId)
      let newDownvotes = targetItem.downvotes
      if (targetItem.userVote && targetItem.userVote.type == "DOWNVOTE") {
        newDownvotes--
      }
      setPlacesData({
        ...placesData, places: placesData.places.map((place) => {
          if (place.id == placeId) {
              return {
                ...place, userVote: { type: "UPVOTE" }, downvotes: newDownvotes, upvotes: targetItem.upvotes + 1
              }
          } else {
            return place
          }
        })
      })
    }
  }

  async function onDownvoteClick(placeId) {
    const targetItem = placesData.places.find(item => item.id == placeId)
    if (targetItem.userVote && targetItem.userVote.type == "DOWNVOTE") {
        removeVote(placeId, targetItem)
    } else {
      const placeRepository = new PlaceRepository()
      placeRepository.downvotePlace(placeId)
      let newUpvotes = targetItem.upvotes
      if (targetItem.userVote && targetItem.userVote.type == "UPVOTE") {
        newUpvotes--
      }
      setPlacesData({
        ...placesData, places: placesData.places.map((place) => {
          if (place.id == placeId) {
              return {
                ...place, userVote: { type: "DOWNVOTE" }, upvotes: newUpvotes, downvotes: targetItem.downvotes + 1
              }
          } else {
            return place
          }
        })
      })
    }
  }

  async function addPlaceSuggestionToCuration(suggestionId, suggestionTitle) {
    setFullScreenLoadingText(`Adding ${suggestionTitle} to collection ...`)
    const curationRepository = new CurationRepository()
    const savedPlace = await curationRepository.addPlaceSuggestionToCuration(suggestionId, curationId)
    setFullScreenLoadingText(null)


    const existingPlaces = placesData.places
    const processedAddedPlace = {
      ...savedPlace, filterOptions: [], filterOptionIds: [],
    }
    existingPlaces.unshift(processedAddedPlace)
    setPlacesData({
      ...placesData, places: existingPlaces
    })
    setPlaceSuggestions(
      placeSuggestions.filter((suggestion) => {
        return suggestion.id != suggestionId
      })
    )
  }

  function handleItemClick(itemId, item) {
    setCurationScrollState({
      scrollPosition: mainListRef.current.scrollTop,
      isShowingList: isShowingList,
    })
    const selectedFilterOptionIds = item.filterOptionIds
    console.log(`selectedFilterOptionIds ${JSON.stringify(selectedFilterOptionIds)}`)
    // curationData.filters.filter((item) => item.filterOptions.map((option) => option.id).includes())
    const placeRelevantOptions = []
    const filtersWithRelevantOptions = curationData.filters.map((filter) => {
      const filteredOptions = filter.filterOptions.filter((option) => selectedFilterOptionIds.includes(option.id))
      placeRelevantOptions.push(...filteredOptions)
      return {
        ...filter, filterOptions: filteredOptions.map((option) => option.id)
      }
    }).filter((filter) => filter.filterOptions.length > 0)

    setPlaceDetails(
      {
        ...item, filters: filtersWithRelevantOptions, filterOptions: placeRelevantOptions, isCurrentUserAllowedToViewComments: !placesData.isPlaceListSneakPeek
      }
    )
    // console.log(`map button ref ${getComputedStyle(mapButtonRef.current).display}`)
    navigate(`/places/${itemId}`)
  }

  function handleMembersClick(position) {
    setCurationScrollState({
      scrollPosition: mainListRef.current.scrollTop,
      isShowingList: isShowingList,
    })
    logEvent(analytics, AnalyticsEvent.CURATION_MEMBERS_CLICK, {
      curation_id: curationId,
      position: position
    })
    navigate(`/collections/${curationData.curation.id}/members`)
  }

  function handlePopupItemClick(itemId, item) {
    logEvent(analytics, AnalyticsEvent.MAP_PLACE_ROW_CLICK, {
      curation_id: curationData.curation.id,
      place_id: itemId
    })
    handleItemClick(itemId, item)
  }

  async function getPlacesAndSetState(selectedFilters, sortType, coordinates, distance, visits) {
    console.log(selectedFilters)
    setIsListLoading(true)
    const filterOptionMap = filtersToFilterOptionsMap(curationData.filters)
    const placesResponse = await getPlaces(supabase, curationData.curation.id, filterOptionMap, selectedFilters, sortType, coordinates, distance, visits)
    const placeList = placesResponse.places
    setPlacesData(
      {
        places: placeList,
        selectedSortType: sortType,
        selectedFilterOptions: selectedFilters,
        selectedFilterDistance: distance,
        selectedVisitOptions: visits,
        selectedSearchInput: placesData.selectedSearchInput,
        totalPlaceCount: placesResponse.totalPlaceCount,
        isPlaceListSneakPeek: placesResponse.isPlaceListSneakPeek
      }
    )
    setIsListLoading(false)
  }

  function onFilterButtonClick() {
    logEvent(analytics, AnalyticsEvent.FILTER_BUTTON_CLICK, {
      curation_id: curationData.curation.id
    })
    setIsFilterBottomSheetOpen(true)
    // document.getElementById(`filter-modal`).showModal()
  }

  function onSortButtonClick() {
    logEvent(analytics, AnalyticsEvent.SORT_BUTTON_CLICK, {
      curation_id: curationData.curation.id
    })
    // document.getElementById(`sort-modal`).showModal()
    setIsSortBottomSheetOpen(true)
  }


  function onSearchButtonClick() {
    logEvent(analytics, AnalyticsEvent.SEARCH_BUTTON_CLICK, {
      curation_id: curationData.curation.id
    })
    // document.getElementById(`sort-modal`).showModal()
    setIsSearchBottomSheetOpen(true)
  }

  function onFilterModalDismiss() {
    // document.getElementById(`filter-modal`).close()
    setIsFilterBottomSheetOpen(false)
  }

  async function onApplyFilterClick(filterOptionState, filterDistance, visitFilterState) {
    const searchParameters = {}
    setCurationMapConfig(null)
    console.log(`apply filter distance ${filterDistance} ${filterOptionState}`)
    setIsListLoading(true)
    const filterOptionStateMap = {}
    filterOptionState.forEach((option) => {
        filterOptionStateMap[option.id] = option.isSelected
    })
    const updatedFilterOptions = placesData.selectedFilterOptions.filter(
      (filterOptionId) => !(filterOptionId in filterOptionStateMap) || filterOptionStateMap[filterOptionId]
    )
    filterOptionState.filter((option) => option.isSelected).forEach((option) => {
      if (!updatedFilterOptions.includes(option.id)) {
        updatedFilterOptions.push(option.id)
      }
    })
    let currentSortType = placesData.selectedSortType
    let coordinates = null

    logEvent(analytics, AnalyticsEvent.FILTER_APPLY_CLICK, {
      curation_id: curationData.curation.id,
      filter_distance: filterDistance
    })

    if (currentSortType == "distance" || filterDistance != null) {
      setFullScreenLoadingText("Fetching location ...")
      coordinates = await getUserLocation()
    }
    setFullScreenLoadingText(null)
    if (coordinates == null && currentSortType == "distance") {
      currentSortType = "recommended"
      // setSelectedSortType("last_updated")
    }

    if (coordinates == null && filterDistance != null) {
      filterDistance = null
      console.log("setting existing distance to null")
    }

    setSearchParams(params => {
      console.log(`existing params ${JSON.stringify(visitFilterState)}`)
      if (updatedFilterOptions.length > 0) {
        params.set("filters", updatedFilterOptions.join());
      } else {
        params.delete("filters")
      }
      if (filterDistance) {
        params.set("filterDistance",filterDistance);
      } else {
        params.delete("filterDistance")
      }
      if (visitFilterState.visited) {
        params.set("isOnlyVisited",true);
      } else {
        params.delete("isOnlyVisited")
      }
      if (visitFilterState.nonVisited) {
        params.set("isOnlyNonVisited",true);
      } else {
        params.delete("isOnlyNonVisited")
      }
      return params;
    })
    console.log(updatedFilterOptions)
    setPlacesData(
      {
        places: [],
        selectedSortType: currentSortType,
        selectedFilterOptions: updatedFilterOptions,
        selectedFilterDistance: filterDistance,
        selectedVisitOptions: visitFilterState,
        selectedSearchInput: placesData.selectedSearchInput,
        totalPlaceCount: 0,
        isPlaceListSneakPeek: false
      }
    )
    getPlacesAndSetState(updatedFilterOptions, currentSortType, coordinates, filterDistance, visitFilterState)
  }

  function onApplySearchClick(input) {
    console.log(`on apply search click ${input}`)
    setIsSearchBottomSheetOpen(false)
    setPlacesData({
      ...placesData, 
      selectedSearchInput: input
    })
    setSearchParams(params => {
      if (input == null || input.length == 0) {
        params.delete("searchInput")
      } else {
        params.set("searchInput", input);
      }
      return params;
    })
  }

  async function onApplySortTypeClick(sortType) {
    logEvent(analytics, AnalyticsEvent.SORT_OPTION_CLICK, {
      curation_id: curationData.curation.id,
      sort_type: sortType
    })
    setIsSortBottomSheetOpen(false)
    // setIsListLoading(true)
    setFullScreenLoadingText("Fetching location ...")
    let coordinates = null
    if (sortType == "distance") {
      coordinates = await getUserLocation()
      if (coordinates == null) {
        sortType = "recommended"
      }
    }
    let filterDistance = placesData.selectedFilterDistance
    if (coordinates == null && placesData.selectedFilterDistance != null) {
      filterDistance = null
    }

    setSearchParams(params => {
      if (sortType != "recommended") {
        params.set("sort", sortType);
      } else {
        params.delete("sort")
      }
      return params;
    })

    setPlacesData(
      {
        places: [],
        selectedSortType: sortType,
        selectedFilterOptions: placesData.selectedFilterOptions,
        selectedFilterDistance: filterDistance,
        selectedVisitOptions: placesData.selectedVisitOptions,
        selectedSearchInput: placesData.selectedSearchInput,
        totalPlaceCount: 0,
        isPlaceListSneakPeek: false
      }
    )
    setFullScreenLoadingText(null)
    mainListRef.current.scrollTo(0, 0)
    getPlacesAndSetState(placesData.selectedFilterOptions, sortType, coordinates, filterDistance, placesData.selectedVisitOptions)
  }

  function onBackPressed() {
    navigate(`/`, { replace: true })
  }

  function onToggleToMapClick() {
    navigate(`/collections/${curationId}/map`)
  }

  function onToggleToListClick() {
    window.history.back()
  }

  const shareContent = async (title, url) => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Roambear",
          text: title,
          url: url,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      alert("Sharing not supported on this device.");
    }
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log("Copied to clipboard:", text);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  Array.prototype.filterPlacesByName = function (searchInput) {
    if (!searchInput) return this; // Return all places if search input is empty
  
    const lowerCasedInput = searchInput.toLowerCase();
  
    return this.filter(place => 
      place.title && place.title.toLowerCase().includes(lowerCasedInput)
    );
  }

  return (
    <div className='h-screen w-screen bg-base-100'>
      <Helmet><title>{curationData.curation.title ? curationData.curation.title : "Loading ..."} | Roambear</title></Helmet>
      <div className={`flex flex-col h-screen fixed w-screen top-0`}>
        <div ref={topRef} className={`fixed md:relative ease-out ease-in duration-300 z-30 w-full md:block md:top-0 ${(!isShowingList && !isDesktopView) ? "bg-base-100" : ""}`}>
          <CoorayAppBar 
            shouldHideTitleInSmall={isTitleVisible}
            shouldHideTitleInBig={isTitleVisible}
            title={curationData.curation.title}
            navigationType={NavigationButtonType.Roambear}
            rightDropdownItems={appBarDropdownItems}
            onBackPressed={onBackPressed}
            endItems={[
              !placesData.isPlaceListSneakPeek && !fullScreenLoadingText && (
                <button className="btn btn-ghost md:btn-primary" onClick={() => { 
                  logEvent(analytics, AnalyticsEvent.ADD_PLACE_VIEW, {
                    curation_id: curationId,
                    position: "navbar"
                  })
                  navigate(`/collections/${curationData.curation.id}/add`)
                }}>
                  <svg className='w-6 h-6' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path className='stroke-base-content md:stroke-primary-content' d="M4 12H20M12 4V20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  <span className='normal-case text-base font-bold hidden md:block md:text-primary-content'>Add Place</span>
                </button>
              )
            //   !placesData.isPlaceListSneakPeek && !fullScreenLoadingText && (<button className="btn btn-ghost" onClick={() => {
            //     handleMembersClick("navbar")
            //   } }>
            //   <svg className='w-6 h-6' viewBox="0 0 24 24">
            //       <g stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
            //           <g id="User" transform="translate(-480.000000, -48.000000)" fill-rule="nonzero">
            //               <g id="group_fill" transform="translate(480.000000, 48.000000)">
            //                   <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z" id="MingCute" fill-rule="nonzero"></path>
            //                   <path className='fill-base-content' d="M13,13 C15.2091,13 17,14.7909 17,17 L17,18.5 C17,19.3284 16.3284,20 15.5,20 L3.5,20 C2.67157,20 2,19.3284 2,18.5 L2,17 C2,14.7909 3.79086,13 6,13 L13,13 Z M19,13.0002 C20.6569,13.0002 22,14.3434 22,16.0002 L22,17.5002 C22,18.3287 21.3284,19.0002 20.5,19.0002 L19,19.0002 L19,17 C19,15.3645 18.2148,13.9125 17.0008,13.0002 L19,13.0002 Z M9.5,3 C11.9853,3 14,5.01472 14,7.5 C14,9.98528 11.9853,12 9.5,12 C7.01472,12 5,9.98528 5,7.5 C5,5.01472 7.01472,3 9.5,3 Z M18,6 C19.6569,6 21,7.34315 21,9 C21,10.6569 19.6569,12 18,12 C16.3431,12 15,10.6569 15,9 C15,7.34315 16.3431,6 18,6 Z"></path>
            //               </g>
            //           </g>
            //       </g>
            //   </svg>
            // </button>)
            ]}
          />
        </div>
        <div className='grid grid-cols-2 flex-1 overflow-y-hidden relative'>
        <div ref={mainListRef} className={`col-span-2 bg-base-100 md:col-span-1 w-full max-h-full overflow-y-scroll order-last z-10 pb-20 md:pb-16 md:pt-0 ${ isShowingList || isDesktopView ? "" : "hidden" } md:order-1 absolute md:relative min-h-full`}>
        {
                curationData.curation.coverPhoto && <div className='md:p-4'>
                <img 
                className='h-72 md:h-56 w-full object-cover md:rounded-md'
                src={curationData.curation.coverPhoto}
                />
                </div>
              }
              {
                !curationData.curation.coverPhoto && <div className='w-full pt-16 md:pt-0'></div>
              }
              {
                curationData.curation && <div className="max-w-full text-base-content text-xs md:text-sm px-4 flex flex-row items-center pt-4">
                  <span>
                  {
                    curationData.curation.isPrivate && <svg className='w-4 h-4' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path className='stroke-base-content' d="M12 14.5V16.5M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C15.9474 10 16.5286 10 17 10.0288M7 10.0288C6.41168 10.0647 5.99429 10.1455 5.63803 10.327C5.07354 10.6146 4.6146 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C18.0057 10.1455 17.5883 10.0647 17 10.0288M7 10.0288V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V10.0288" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  }
                  {
                    curationData.curation.isPrivate == false && <svg className='w-4 h-4' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className='stroke-base-content' d="M16.584 6C15.8124 4.2341 14.0503 3 12 3C9.23858 3 7 5.23858 7 8V10.0288M12 14.5V16.5M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C16.8802 10 17.7202 10 18.362 10.327C18.9265 10.6146 19.3854 11.0735 19.673 11.638C20 12.2798 20 13.1198 20 14.8V16.2C20 17.8802 20 18.7202 19.673 19.362C19.3854 19.9265 18.9265 20.3854 18.362 20.673C17.7202 21 16.8802 21 15.2 21H8.8C7.11984 21 6.27976 21 5.63803 20.673C5.07354 20.3854 4.6146 19.9265 4.32698 19.362C4 18.7202 4 17.8802 4 16.2V14.8C4 13.1198 4 12.2798 4.32698 11.638C4.6146 11.0735 5.07354 10.6146 5.63803 10.327C5.99429 10.1455 6.41168 10.0647 7 10.0288Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  }
                  </span>
                  {
                    curationData.curation.isPrivate && <span className='pl-1'>Private</span>
                  }
                  {
                    curationData.curation.isPrivate == false && <span className='pl-1'>Public</span>
                  }
                  {
                    placesData.totalPlaceCount > 0 && <span className='px-2'>·</span>
                  }
                  { placesData.totalPlaceCount > 0 && <span>{placesData.totalPlaceCount} place{ placesData.totalPlaceCount > 1? "s": "" }</span>}
                
                 </div>
              }
              {
                  curationData.curation.title && <div className='pt-2 px-4 font-bold text-xl' ref={titleRef}>
                    { curationData.curation.title }
                  </div>
              }
              {
                <div className="max-w-full text-base-content text-sm md:text-base px-4 py-2">{curationData.curation.description}</div>
              }
              {
                <div className='flex flex-row'>
                {
                  <div className='w-4'></div>
                }
                {
                  curationData.curation.url && <div className="avatar-group py-2">
                  <div className="avatar placeholder cursor-pointer" onClick={() => {
                    generateInvitationLink(curationId)
                  }}>
                        <div className="w-12 bg-secondary text-secondary-content p-4">
                        <svg className='w-6 h-6' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className='stroke-secondary-content' d="M12.7917 15.7991L14.2223 14.3676C16.5926 11.9959 16.5926 8.15054 14.2223 5.7788C11.8521 3.40707 8.0091 3.40707 5.63885 5.7788L2.77769 8.64174C0.407436 11.0135 0.407436 14.8588 2.77769 17.2306C3.87688 18.3304 5.29279 18.9202 6.73165 19" stroke-width="1.5" stroke-linecap="round"/>
                        <path className='stroke-secondary-content' d="M11.2083 8.20092L9.77769 9.63239C7.40744 12.0041 7.40744 15.8495 9.77769 18.2212C12.1479 20.5929 15.9909 20.5929 18.3612 18.2212L21.2223 15.3583C23.5926 12.9865 23.5926 9.14118 21.2223 6.76945C20.1231 5.66957 18.7072 5.07976 17.2683 5" stroke-width="1.5" stroke-linecap="round"/>
                        </svg>
                        </div>
                      </div>
                      </div>
                }
                {
                    curationMemberResponse != null && curationMemberResponse.members.length > 0 && <div className="avatar-group -space-x-6 rtl:space-x-reverse px-0 py-2">
                    {
                      curationMemberResponse.members.slice(0, 5).map((member) => {
                        return <div className="avatar cursor-pointer" onClick={() => {
                          handleMembersClick("header")
                        }}>
                          <div className="w-12 bg-primary text-center">
                            { !member.profilePicture && <div className='h-12 w-12 flex items-center place-content-center'><div className="text-base font-bold text-primary-content">{ member.name[0] }</div></div> }
                            { member.profilePicture && <img src={member.profilePicture} /> }
                          </div>
                        </div>
                      })
                    }
                    {
                      curationMemberResponse.members.length > 5 && <div className="avatar placeholder cursor-pointer" onClick={() => {
                        handleMembersClick("header")
                      }}>
                        <div className="w-12 bg-neutral text-neutral-content">
                          <span>{ curationMemberResponse.members.length - 5 }+</span>
                        </div>
                      </div>
                    }
                    {
                      curationMemberResponse.members.length <= 5 && <div className="avatar placeholder cursor-pointer" onClick={() => {
                        handleMembersClick("header")
                      }}>
                        <div className="w-12 bg-neutral text-neutral-content">
                        <svg className='w-6 h-6' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="fill-neutral-content" d="M12 13.75C12.9665 13.75 13.75 12.9665 13.75 12C13.75 11.0335 12.9665 10.25 12 10.25C11.0335 10.25 10.25 11.0335 10.25 12C10.25 12.9665 11.0335 13.75 12 13.75Z"/>
                        <path className="fill-neutral-content" d="M19 13.75C19.9665 13.75 20.75 12.9665 20.75 12C20.75 11.0335 19.9665 10.25 19 10.25C18.0335 10.25 17.25 11.0335 17.25 12C17.25 12.9665 18.0335 13.75 19 13.75Z"/>
                        <path className="fill-neutral-content" d="M5 13.75C5.9665 13.75 6.75 12.9665 6.75 12C6.75 11.0335 5.9665 10.25 5 10.25C4.0335 10.25 3.25 11.0335 3.25 12C3.25 12.9665 4.0335 13.75 5 13.75Z"/>
                        </svg>
                        </div>
                      </div>
                    }
                    </div>
                }
                </div>
              }
              {
                  !placesData.isPlaceListSneakPeek && !fullScreenLoadingText && curationData.curation.title && <div className='px-4 pt-4'>
                    <button className="btn text-base btn-primary normal-case rounded-full" onClick={() => { 
                      navigate(`/collections/${curationData.curation.id}/add`)
                      logEvent(analytics, AnalyticsEvent.ADD_PLACE_CLICK, {
                        curation_id: curationData.curation.id,
                        position: "list"
                      })
                    }}>
                      + Add Place
                    </button>
                  </div>
              }
              {
                <div className='h-4'></div>
              }
              {
                  curationData.curation.title && <div className={`sticky top-16 md:top-0 z-20 relative`}>
                    <CurationListFilterBar 
                    activeFilterSize={placesData.selectedFilterOptions.length + (placesData.selectedVisitOptions.visited ? 1 : 0) + (placesData.selectedVisitOptions.nonVisited ? 1 : 0) + (placesData.selectedFilterDistance == null ? 0 : 1)} 
                    onFilterButtonClick={onFilterButtonClick} 
                    onSortButtonClick={onSortButtonClick}
                    onSearchButtonClick={onSearchButtonClick}
                    isSearchInputOn={placesData.selectedSearchInput && placesData.selectedSearchInput.length > 0}
                    />
                  </div>
              }
              {
                placesData.places.filterPlacesByName(placesData.selectedSearchInput).map((place, index) => {
                  if (placesData.places.length > 3 && index == 3 || placesData.places.length <= 3 && index == 0) {
                    return <div>
                      <CurationListItem 
                      onPositiveClick={(event) => { onUpvoteClick(place.id); event.stopPropagation() }}
                      onNegativeClick={(event) => { onDownvoteClick(place.id); event.stopPropagation() }}
                      clickEvent={(_) => { handleItemClick(place.id, place) }} 
                      placeItem={place} 
                      key={place.key} 
                      hoverInEvent={() => {
                          setCurationHoverId(place.id)
                      }}
                      hoverOutEvent={() => {
                          setCurationHoverId(null)
                      }}
                      overallVotes={place.upvotes - place.downvotes}
                      userVote={ (place.userVote ? place.userVote.type : null)}
                      isTopVote={ place.isTopVote }
                      />
                      {
                        placeSuggestions.length > 0 && <div className='pt-8 pb-4 px-4 font-bold text-base w-full flex items-center'>
                          <span className='pr-4'>Suggestions</span> <div className="badge py-1 h-auto badge-neutral inline-flex items-baseline">
                          <svg className='w-4 h-4 self-center fill-neutral-content' viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg"><path d="M22.2819 9.8211a5.9847 5.9847 0 0 0-.5157-4.9108 6.0462 6.0462 0 0 0-6.5098-2.9A6.0651 6.0651 0 0 0 4.9807 4.1818a5.9847 5.9847 0 0 0-3.9977 2.9 6.0462 6.0462 0 0 0 .7427 7.0966 5.98 5.98 0 0 0 .511 4.9107 6.051 6.051 0 0 0 6.5146 2.9001A5.9847 5.9847 0 0 0 13.2599 24a6.0557 6.0557 0 0 0 5.7718-4.2058 5.9894 5.9894 0 0 0 3.9977-2.9001 6.0557 6.0557 0 0 0-.7475-7.0729zm-9.022 12.6081a4.4755 4.4755 0 0 1-2.8764-1.0408l.1419-.0804 4.7783-2.7582a.7948.7948 0 0 0 .3927-.6813v-6.7369l2.02 1.1686a.071.071 0 0 1 .038.052v5.5826a4.504 4.504 0 0 1-4.4945 4.4944zm-9.6607-4.1254a4.4708 4.4708 0 0 1-.5346-3.0137l.142.0852 4.783 2.7582a.7712.7712 0 0 0 .7806 0l5.8428-3.3685v2.3324a.0804.0804 0 0 1-.0332.0615L9.74 19.9502a4.4992 4.4992 0 0 1-6.1408-1.6464zM2.3408 7.8956a4.485 4.485 0 0 1 2.3655-1.9728V11.6a.7664.7664 0 0 0 .3879.6765l5.8144 3.3543-2.0201 1.1685a.0757.0757 0 0 1-.071 0l-4.8303-2.7865A4.504 4.504 0 0 1 2.3408 7.872zm16.5963 3.8558L13.1038 8.364 15.1192 7.2a.0757.0757 0 0 1 .071 0l4.8303 2.7913a4.4944 4.4944 0 0 1-.6765 8.1042v-5.6772a.79.79 0 0 0-.407-.667zm2.0107-3.0231l-.142-.0852-4.7735-2.7818a.7759.7759 0 0 0-.7854 0L9.409 9.2297V6.8974a.0662.0662 0 0 1 .0284-.0615l4.8303-2.7866a4.4992 4.4992 0 0 1 6.6802 4.66zM8.3065 12.863l-2.02-1.1638a.0804.0804 0 0 1-.038-.0567V6.0742a4.4992 4.4992 0 0 1 7.3757-3.4537l-.142.0805L8.704 5.459a.7948.7948 0 0 0-.3927.6813zm1.0976-2.3654l2.602-1.4998 2.6069 1.4998v2.9994l-2.5974 1.4997-2.6067-1.4997Z"/></svg> <span className='self-center pl-2 text-sm font-normal'>by ChatGPT</span>
                          </div>
                        </div>
                      }
                      {
                        placeSuggestions.length > 0 && <div className="carousel carousel-center rounded-box space-x-4 px-4 pb-8 w-full">
                          {
                            placeSuggestions.map((suggestion) => {
                              return <div className="carousel-item w-11/12 md:w-5/12">
                                <PlaceSuggestionItem 
                                  item={suggestion}
                                  onAddSuggestionToCollectionClick={() => {
                                    addPlaceSuggestionToCuration(
                                      suggestion.id, suggestion.title
                                    )
                                  }}
                                />
                              </div>
                            })
                          }
                        </div>
                      }
                    </div>
                  } else {
                    return <CurationListItem 
                    onPositiveClick={(event) => { onUpvoteClick(place.id); event.stopPropagation() }}
                    onNegativeClick={(event) => { onDownvoteClick(place.id); event.stopPropagation() }}
                    clickEvent={(_) => { handleItemClick(place.id, place) }} 
                    placeItem={place} 
                    key={place.key}
                    hoverInEvent={() => {
                      setCurationHoverId(place.id)
                    }}
                    hoverOutEvent={() => {
                      setCurationHoverId(null)
                    }}
                    overallVotes={place.upvotes - place.downvotes}
                    userVote={ (place.userVote ? place.userVote.type : null)}
                    isTopVote={ place.isTopVote }
                    />
                  }
                })
              }
              {!isListLoading && curationData.curation.title && placesData.places.length == 0 &&
                <div className='w-full flex flex-col items-center mt-8 min-h-[80vh]'>
                  <svg className="w-16 h-16 fill-base-content" version="1.1" viewBox="0 0 310.883 310.883">
                    <path d="M299.459,174.267c0,0-16.433-71.773-16.707-72.356c-3.429-10.694-17.078-19.279-40.725-25.565
                        c-23.243-6.181-53.993-9.584-86.586-9.584c-32.592,0-63.343,3.403-86.586,9.584c-23.657,6.29-37.308,14.879-40.729,25.58
                        c-0.272,0.578-16.702,72.342-16.702,72.342C4.778,176.576,0,182.879,0,190.312c0,18.79,17.893,33.075,53.18,42.458
                        c27.533,7.32,63.85,11.353,102.261,11.353c0.002,0,0.004,0,0.006,0c38.41,0,74.724-4.031,102.255-11.352
                        c35.287-9.383,53.18-23.668,53.18-42.459C310.883,182.879,306.105,176.576,299.459,174.267z M211.452,189.198
                        c0,7.987-6.498,14.486-14.485,14.486c-7.755,0-14.107-6.124-14.471-13.79h-54.11c-0.365,7.666-6.715,13.79-14.469,13.79
                        c-7.988,0-14.486-6.499-14.486-14.486c0-3.783,1.458-7.232,3.842-9.815c-2.384-2.583-3.842-6.032-3.842-9.815
                        c0-7.987,6.499-14.486,14.486-14.486c7.754,0,14.104,6.124,14.469,13.79h54.11c0.364-7.666,6.716-13.79,14.471-13.79
                        c7.987,0,14.485,6.499,14.485,14.486c0,3.783-1.458,7.232-3.842,9.815C209.994,181.966,211.452,185.415,211.452,189.198z
                        M235.357,120c-21.545,5.448-49.926,8.449-79.916,8.449c-29.99,0-58.371-3.001-79.916-8.449
                        c-20.722-5.24-28.012-10.998-29.796-13.382c1.8-2.425,9.104-8.177,29.8-13.409c21.544-5.448,49.924-8.448,79.912-8.448
                        c29.987,0,58.367,3,79.911,8.448c20.654,5.223,27.97,10.961,29.789,13.395C263.329,109.033,256.023,114.773,235.357,120z"/>
                    </svg>
                  <div className='font-normal text-base-content mt-2'>Nothing here yet.</div>
                  {
                      placeSuggestions.length > 0 && <div className='pt-12 pb-4 px-4 font-bold text-base w-full'>
                        Suggestions
                      </div>
                  }
                  {
                    placeSuggestions.length > 0 && <div className="carousel carousel-center rounded-box space-x-4 px-4 w-full">
                      {
                        placeSuggestions.map((suggestion) => {
                          return <div className="carousel-item w-11/12 md:w-5/12">
                            <PlaceSuggestionItem 
                              item={suggestion}
                              onAddSuggestionToCollectionClick={() => {
                                addPlaceSuggestionToCuration(
                                  suggestion.id, suggestion.title
                                )
                              }}
                            />
                          </div>
                        })
                      }
                    </div>
                  }
                </div>
              }
              {isListLoading &&
              <div className='w-full flex flex-col items-center col-span-2 py-8 min-h-[100vh]'>
                <div className="loading loading-infinity loading-lg"></div>
                <div className='font-normal text-base-content mt-2'>Loading ...</div>
              </div>
              }
              {placesData.isPlaceListSneakPeek &&
              <div className='w-full flex flex-col items-center col-span-2 py-4'>
                <div className='font text-base text-base-content mt-2'>📍 Join to view {placesData.totalPlaceCount > 10 ? 
                placesData.totalPlaceCount - 10 : ""} more spots ...</div>
              </div>
              }
              {
                !isListLoading && !curationData.curation.title && <div className='w-full flex flex-col items-center col-span-2 py-8 min-h-[100vh]'>
                </div>
              }
              <div className='col-span-2'>
                <CoorayFooter />
              </div>
          </div>
          {
            <div className={`col-span-2 md:col-span-1 h-full max-h-screen relative order-1 md:order-last bg-base-300 pt-16 md:pt-0 max-w-full overflow-x-hidden`}>
              { (!isShowingList || isDesktopView) && placesData.places.length > 0 && <div className='relative w-full h-full'>
                  {/* {
                      curationData.curation.title && <div className={`z-20 md:hidden relative`}>
                        <CurationListFilterBar activeFilterSize={placesData.selectedFilterOptions.length + (placesData.selectedVisitOptions.visited ? 0 : 1) + (placesData.selectedVisitOptions.nonVisited ? 0 : 1) + (placesData.selectedFilterDistance == null ? 0 : 1)} onFilterButtonClick={onFilterButtonClick} onSortButtonClick={onSortButtonClick}/>
                      </div>
                  } */}
                  <CurationMap
                    isDesktop={true} 
                    ref={mapRef}
                    mapMarkerEmoji={curationData.curation.emoji != null ? curationData.curation.emoji : "🔵"}
                    handlePopupItemClick={handlePopupItemClick}
                    onToggleBackToListClick={() => { onToggleToListClick() }}
                    analytics={analytics}
                    onFilterButtonClick={onFilterButtonClick}
                  />

              </div>
              }

            {isListLoading && !isShowingList && 
              <div className='w-full flex flex-col items-center justify-center col-span-2 py-8'>
                <div className="loading loading-infinity loading-lg"></div>
                <div className='font-normal text-base-content mt-2'>Loading ...</div>
              </div>
              }
            </div>
          }
        </div>
        <FilterModal 
          isFilterModalOpen={isFilterBottomSheetOpen}
          filters={curationData.filters}
          selectedFilterOptions={placesData.selectedFilterOptions}
          existingDistance={placesData.selectedFilterDistance}
          onApplyClick={onApplyFilterClick}
          onFilterModalDismiss={onFilterModalDismiss}
          visitSelections={placesData.selectedVisitOptions}
        />
        <SortModal 
          isSortModalOpen={isSortBottomSheetOpen}
          selectedSortType={placesData.selectedSortType}
          onSortClick={(type)  => {
            onApplySortTypeClick(type)
          } }
          onSortModalDismiss={() => {
            setIsSortBottomSheetOpen(false)
          }}
        />

        <SearchModal 
          existingSearchQuery={placesData.selectedSearchInput}
          isSearchBottomSheetOpen={isSearchBottomSheetOpen}
          onSearchApplyClick={(query)  => {
            // onApplySortTypeClick(type)
            onApplySearchClick(query)
          } }
          onSearchClearClick={() => {
            onApplySearchClick("")
          }}
          onSearchModalDismiss={() => {
            setIsSearchBottomSheetOpen(false)
          }}
        />
        <CollectionWelcomeModal 
          isWelcomeModalOpen={isWelcomeToCollectionBottomSheetOpen}
          collectionName={curationData.curation.title}
          onWelcomeModalDismiss={() => {
            setIsWelcomeToCollectionBottomSheetOpen(false)
            onApplySortTypeClick("recommended")
            getCurationMembers(curationData.curation.id)
          }}
          totalPlaceNumber={placesData.totalPlaceCount}
        />

        <GeneratedInvitationLinkModal 
          isInvitationLinkModalOpen={generatedInvitationLink != null}
          url={generatedInvitationLink}
          onGeneratedInvitationLinkDismiss={() => {
            setGeneratedInvitationLink(null)
          }}
          onShareLinkClick={() => {
              shareContent(generatedInvitationTitle, generatedInvitationLink)
          }}
          onCopyLinkClick={() => {
              copyToClipboard(generatedInvitationLink)
          }}
        />
        
        <dialog id="confirm-deletion-modal" className="modal">
            <div className="modal-box w-11/12 max-w-5xl">
                <h3 className="font-bold text-lg">Confirm deletion?</h3>
                <p className="py-2 text-base">Are you sure you want to delete <span className="font-bold">{curationData.curation.title}</span>?</p>
                <div className="modal-action">
                    <button className="btn btn-ghost" onClick={() => { deleteCuration() }}>Yes</button>
                    <button className="btn btn-ghost" onClick={() => { document.getElementById("confirm-deletion-modal").close() }}>Cancel</button>
                </div>
            </div>
        </dialog>

        {fullScreenLoadingText &&
          <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/70 z-50'>
              <div className="loading loading-infinity loading-lg"></div>
              <div className='font-normal text-base-content mt-2'>{ fullScreenLoadingText }</div>
          </div>}
      </div>

        {
          !fullScreenLoadingText && placesData.isPlaceListSneakPeek && <div className='w-full md:w-1/2 mt-8 fixed bottom-0 bg-gradient-to-b from-transparent via-base-100/80 via-20% to-base-100 pt-16 flex flex-col md:flex-row items-end'>
            <div className='w-full md:w-1/2 flex flex-col items-center px-4 md:pb-4'>
            <div className='font-normal text-base-content mt-2'>Join this collection to view all {placesData.totalPlaceCount == 0 && "the"}{placesData.totalPlaceCount > 0 && <b>{placesData.totalPlaceCount}</b>} spots!</div>
            <button className="btn normal-case text-base btn-success my-2 rounded-full" onClick={() => {
              // setIsWelcomeToCollectionBottomSheetOpen(true)
              joinPublicCuration(curationData.curation.id)
            }}><svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="stroke-success-content" d="M5.5713 14.5L9.46583 18.4141M18.9996 3.60975C17.4044 3.59505 16.6658 4.33233 16.4236 5.07743C16.2103 5.73354 16.4052 7.07735 15.896 8.0727C15.4091 9.02443 14.1204 9.5617 12.6571 9.60697M20 7.6104L20.01 7.61049M19 15.96L19.01 15.9601M7.00001 3.94926L7.01001 3.94936M19 11.1094C17.5 11.1094 16.5 11.6094 15.5949 12.5447M10.2377 7.18796C11 6.10991 11.5 5.10991 11.0082 3.52734M3.53577 20.4645L7.0713 9.85791L14.1424 16.929L3.53577 20.4645Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>Join Collection</button>
            </div>
            <div className='pl-8 md:pl-0 w-full md:w-1/2 flex flex-col items-start'>
            <img 
              className='w-48 md:w-5/6'
              src="https://oesctluxvegwtcqckcdg.supabase.co/storage/v1/object/public/cooray-shared-resources//join-collection.png?v=1"
            />
            </div>
            </div>
        }

        {
          curationData.curation.title && currentPage == 0 && (isShowingList) && <div className='max-w-1/2 fixed bottom-4 right-4 bg-transparent text-right md:hidden' ref={mapButtonRef}>
          <button className="btn md:btn-lg text-base btn-primary normal-case rounded-full" onClick={() => { onToggleToMapClick() }}>
          <svg className='w-5 h-5' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="stroke-primary-content" d="M9 20L3 17V4L9 7M9 20L15 17M9 20V7M15 17L21 20V7L15 4M15 17V4M9 7L15 4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg> Map
          </button>
          </div>
        }
        {
          !placesData.isPlaceListSneakPeek && <FeedbackButton />
        }
    </div>
    )
  }