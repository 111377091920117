import { LazyLoadImage } from "react-lazy-load-image-component";
import GoogleReviewItem from "../googlereview/GoogleReviewItem";
import ExploreTagItem from "./ExploreTagItem";

export default function ExploreListItem({ placeItem, clickEvent, onAddClick }) {
    const images = placeItem.images.map((image) => {
        return <div className="carousel-item w-1/2 md:w-full h-full">
            <LazyLoadImage className="rounded-md h-full w-full object-cover max-w-full" src={image.url} />
        </div>
    })
    return <div 
        className="px-4 py-4 rounded-md bg-base-200 cursor-pointer hover:bg-base-300 mb-2 md:grid md:grid-cols-5 md:items-center h-full" 
        onClick={clickEvent}
    >
        {
            images.length > 0 && 
            <div className="carousel rounded-lg h-60 mt-3 w-full space-x-4 md:mt-0 md:col-span-2">{images}</div>
        }
        {
            placeItem.tags.length > 0 && <div className={`flex flex-wrap gap-1 items-start md:hidden pt-2`}>
            <button className={`group btn btn-sm normal-case font-normal text-sm rounded-full btn-neutral`}>
                <div className="flex flex-row gap-2 items-center">
                    <svg className="w-4 h-4" viewBox="-0.5 0 48 48">
                        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="Color-" transform="translate(-401.000000, -860.000000)">
                                <g id="Google" transform="translate(401.000000, 860.000000)">
                                    <path d="M9.82727273,24 C9.82727273,22.4757333 10.0804318,21.0144 10.5322727,19.6437333 L2.62345455,13.6042667 C1.08206818,16.7338667 0.213636364,20.2602667 0.213636364,24 C0.213636364,27.7365333 1.081,31.2608 2.62025,34.3882667 L10.5247955,28.3370667 C10.0772273,26.9728 9.82727273,25.5168 9.82727273,24" id="Fill-1" fill="#FBBC05"></path>
                                    <path d="M23.7136364,10.1333333 C27.025,10.1333333 30.0159091,11.3066667 32.3659091,13.2266667 L39.2022727,6.4 C35.0363636,2.77333333 29.6954545,0.533333333 23.7136364,0.533333333 C14.4268636,0.533333333 6.44540909,5.84426667 2.62345455,13.6042667 L10.5322727,19.6437333 C12.3545909,14.112 17.5491591,10.1333333 23.7136364,10.1333333" id="Fill-2" fill="#EB4335"></path>
                                    <path d="M23.7136364,37.8666667 C17.5491591,37.8666667 12.3545909,33.888 10.5322727,28.3562667 L2.62345455,34.3946667 C6.44540909,42.1557333 14.4268636,47.4666667 23.7136364,47.4666667 C29.4455,47.4666667 34.9177955,45.4314667 39.0249545,41.6181333 L31.5177727,35.8144 C29.3995682,37.1488 26.7323182,37.8666667 23.7136364,37.8666667" id="Fill-3" fill="#34A853"></path>
                                    <path d="M46.1454545,24 C46.1454545,22.6133333 45.9318182,21.12 45.6113636,19.7333333 L23.7136364,19.7333333 L23.7136364,28.8 L36.3181818,28.8 C35.6879545,31.8912 33.9724545,34.2677333 31.5177727,35.8144 L39.0249545,41.6181333 C43.3393409,37.6138667 46.1454545,31.6490667 46.1454545,24" id="Fill-4" fill="#4285F4"></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    { placeItem.reviewScore }<span>⭐️</span>
                </div>
            </button>
            { placeItem.tags.map((tag) => <ExploreTagItem label={tag}/>) }
            </div>
        }
        <div className={`md:col-span-3 md:pl-4 pt-2 flex md:flex-col items-center md:items-start md:h-full gap-2 items-stretch`}>
            <div className={`md:flex md:flex-col md:justify-between md:h-full flex-1`}>
                <div className="top-section w-full py-2">
                <div className="text-base-content text-base md:text-lg font-bold text-base-content">{ placeItem.name }</div>
                {
                    <div className="grow text-sm md:text-base self-center text-base-content line-clamp-3 pt-2">
                        { placeItem.consolidatedReview }
                    </div>
                }
                {
                    placeItem.tags.length > 0 && <div className="grow text-sm md:text-base self-center text-base-content line-clamp-3 pt-2 flex flex-wrap gap-1 hidden md:flex">
                        <button className={`group btn btn-sm normal-case font-normal text-sm rounded-full btn-neutral`}>
                        <div className="flex flex-row gap-2 items-center">
                        <svg className="w-4 h-4" viewBox="-0.5 0 48 48">
                            <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="Color-" transform="translate(-401.000000, -860.000000)">
                                    <g id="Google" transform="translate(401.000000, 860.000000)">
                                        <path d="M9.82727273,24 C9.82727273,22.4757333 10.0804318,21.0144 10.5322727,19.6437333 L2.62345455,13.6042667 C1.08206818,16.7338667 0.213636364,20.2602667 0.213636364,24 C0.213636364,27.7365333 1.081,31.2608 2.62025,34.3882667 L10.5247955,28.3370667 C10.0772273,26.9728 9.82727273,25.5168 9.82727273,24" id="Fill-1" fill="#FBBC05"></path>
                                        <path d="M23.7136364,10.1333333 C27.025,10.1333333 30.0159091,11.3066667 32.3659091,13.2266667 L39.2022727,6.4 C35.0363636,2.77333333 29.6954545,0.533333333 23.7136364,0.533333333 C14.4268636,0.533333333 6.44540909,5.84426667 2.62345455,13.6042667 L10.5322727,19.6437333 C12.3545909,14.112 17.5491591,10.1333333 23.7136364,10.1333333" id="Fill-2" fill="#EB4335"></path>
                                        <path d="M23.7136364,37.8666667 C17.5491591,37.8666667 12.3545909,33.888 10.5322727,28.3562667 L2.62345455,34.3946667 C6.44540909,42.1557333 14.4268636,47.4666667 23.7136364,47.4666667 C29.4455,47.4666667 34.9177955,45.4314667 39.0249545,41.6181333 L31.5177727,35.8144 C29.3995682,37.1488 26.7323182,37.8666667 23.7136364,37.8666667" id="Fill-3" fill="#34A853"></path>
                                        <path d="M46.1454545,24 C46.1454545,22.6133333 45.9318182,21.12 45.6113636,19.7333333 L23.7136364,19.7333333 L23.7136364,28.8 L36.3181818,28.8 C35.6879545,31.8912 33.9724545,34.2677333 31.5177727,35.8144 L39.0249545,41.6181333 C43.3393409,37.6138667 46.1454545,31.6490667 46.1454545,24" id="Fill-4" fill="#4285F4"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        { placeItem.reviewScore }<span>⭐️</span>
                        </div>
                        </button>
                        { placeItem.area && <button className={`group btn btn-sm normal-case font-normal text-sm rounded-full btn-neutral`}>
                            <div className="flex flex-row gap-2 items-center">
                            <span>📍</span>
                            { placeItem.area }
                            </div>
                        </button> }
                        { placeItem.tags.map((tag) => <ExploreTagItem label={tag}/>) }
                    </div>
                }
                </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between self-center md:self-start py-2">
            <button className="btn text-sm btn-success normal-case rounded-full" onClick={(e) => { onAddClick();e.stopPropagation(); }}>
                <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="fill-success-content" fill-rule="evenodd" clip-rule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9L11.25 11.25H9C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75H11.25V15C11.25 15.4142 11.5858 15.75 12 15.75C12.4142 15.75 12.75 15.4142 12.75 15L12.75 12.75H15C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H12.75V9Z"/>
                </svg> Save
            </button>
        </div>

        </div>
    </div>;
}
