import { useState } from "react"
import Sheet from 'react-modal-sheet';

export default function SearchModal(
    { existingSearchQuery, isSearchBottomSheetOpen, onSearchApplyClick, onSearchClearClick, onSearchModalDismiss }
) {
    const [searchInput, setSearchInput] = useState(existingSearchQuery)

    function onSearchInputChange(event) {
        setSearchInput(event.target.value)
    }

    return <Sheet isOpen={isSearchBottomSheetOpen} onClose={() => {onSearchModalDismiss()}} onOpenEnd={() => {}} detent="content-height">
        <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
        <Sheet.Scroller draggableAt="both">
        <div className="pb-8 px-4">
            <div className="font-bold text-lg py-4 flex items-center">
                <svg className={`pointer-events-none fill-none w-4 h-4 left-4 text-base`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="stroke-base-content" d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span className="pl-2">Search</span>
            </div>
            <div className="flex w-full gap-2">
                <div className="form-control flex-1 relative">
                    <input className={`input input-bordered w-full rounded-md z-1`} value={searchInput} placeholder={"Type here ..."} onChange={onSearchInputChange} />
                </div>
                <button className="btn btn-circle btn-success" onClick={() => { onSearchApplyClick(searchInput) }}>
                <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 15L21 21" className="stroke-success-content" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path className="stroke-success-content" d="M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke-width="2"/>
                </svg>
                </button>
                <button className="btn rounded-full normal-case" onClick={() => { setSearchInput(""); onSearchClearClick() }}>
                    Clear
                </button>
            </div>
        </div>
      {/* <div className="modal-action pb-4 px-4">
        <button className="btn" onClick={
            () => {
                onSearchModalDismiss()
            }
            }>Close</button>
      </div>
      <div className="modal-action absolute bottom-0 right-0 bg-base-100 w-full px-6 py-4">
            <button className="btn" onClick={
                () => {
                    onSearchClearClick()
                }
                }>Clear</button>
            <button className="btn btn-success text-success-content mr-2" onClick={
                () => {
                    onSearchApplyClick(searchInput)
                }
            } >Apply
            </button>
            <button className="btn btn-ghost" onClick={
                () => {
                    onSearchModalDismiss()
                }
                }>Close</button>
        </div> */}


        </Sheet.Scroller>
        </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={(_) => {
            onSearchModalDismiss()
        }}/>
    </Sheet>
}