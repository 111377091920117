import { useState, useEffect } from 'react'
import { createClient } from '@supabase/supabase-js'
import CoorayAppBar, { NavigationButtonType } from "../../shared/components/CoorayAppBar"
import FilterChipsContainer from "../../shared/components/FilterChipsContainer"
import AddFilterPage from '../addfilter/AddFilterPage'
import CurationRepository from '../../repository/CurationRepository'
import AddCurationSuccessPage from './AddCurationSuccessPage'
import { useNavigate } from 'react-router-dom'
import { logEvent } from 'firebase/analytics'
import { AnalyticsEvent } from '../../shared/analytics/AnalyticsEvent'
import { Helmet } from 'react-helmet'
import { useAddCurationContext } from './AddCurationContext'
import EditCurationVisibilityItemView from '../../shared/components/EditCurationVisibilityItemView'

const IMAGE_MAX_DIMENSION = 700
const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function AddCurationPage({analytics}) {
    const [filters, setFilters] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [loadingText, setLoadingText] = useState(null)
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [coverPhoto, setCoverPhoto] = useState(null)
    const [pendingCoverFile, setPendingCoverFile] = useState(null)
    const {addCurationData, setAddCurationData} = useAddCurationContext()
    const [isCurationPrivate, setIsCurationPrivate] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {

        setLoadingText("Loading")
        async function getUserLoginState() {
          const {
            data: { user },
          } = await supabase.auth.getUser()
          if (user == null) {
            navigate("/login", {replace: true})
          } else {
            setLoadingText(null)
          }
        }
        getUserLoginState()
        logEvent(analytics, AnalyticsEvent.ADD_CURATION_VIEW, {})
      }, [])

    async function saveCuration(curationTitle, curationDescription, curationFilters, isPrivate) {
        setLoadingText("Saving ...")
        console.log(`saving ${curationTitle}`)
        console.log(`saving ${curationDescription}`)
        console.log(`saving ${curationFilters}`)
        const curationRepository = new CurationRepository(supabase)
        let createdCuration = await curationRepository.createCuration(
            curationTitle, curationDescription, curationFilters, isPrivate
        )
    
        console.log(`created ${JSON.stringify(createdCuration)}`)

        if (pendingCoverFile != null) {
            setLoadingText("Uploading image ...")
            const response = await curationRepository.uploadCoverPicture(
                createdCuration.curation.id, pendingCoverFile
            )
            createdCuration = response
        }

        console.log("redirecting")
        setAddCurationData(createdCuration)
        navigate(`/collections/${createdCuration.curation.id}/success`, {replace: true})
    }

    function onFilterOptionClick(clickedOption) {
    }

    function onSaveClick() {
        saveCuration(
            title,
            description,
            filters.map((filter) => {
                return {
                    filterName: filter.label,
                    options: filter.filterOptions.map((option) => option.label)
                }
            }),
            isCurationPrivate
        )
    }

    function onAddFilterClick() {
        console.log("on add filter click")
        logEvent(analytics, AnalyticsEvent.ADD_CURATION_ADD_FILTER_CLICK, {})
        setCurrentPage(1)
    }

    function onFilterSaveClick(filterName, options) {
        console.log(`on add filter save click ${filterName} ${options.length}`)
        const updatedFilters = filters.concat(
            [
                {
                    label: filterName,
                    filterOptions: options.map((option) => {
                        return {
                            label: option
                        }
                    })
                }
            ]
        )
        setFilters(updatedFilters)
        setCurrentPage(0)
    }

    function onBackPressed() {
        navigate(-1)
    }

    function onAddFilterBackPressed() {
        setCurrentPage(0)
    }

    function onTitleChange(event) {
        console.log(`title input change ${event.target.value}`)
        setTitle(event.target.value)
    }

    function onDescriptionChange(event) {
        console.log(`description input change ${event.target.value}`)
        setDescription(event.target.value)
    }

    async function uploadImageToCuration(fileToBeUploaded) {
        // const imagefile = document.querySelector('#upload-image-input')
        // const fileToBeUploaded = imagefile.files[0]
        // console.log(`image file ${fileToBeUploaded}`)
        // setLoadingText("Uploading image ...")
        // const curationRepository = new  CurationRepository()
        // const updatedCuration = await curationRepository.uploadCoverPicture(
        //     curationId, fileToBeUploaded
        // )
        // setCoverPhoto(updatedCuration.curation.coverPhoto)
        // setLoadingText(null)
    }

    function onFileInputChange(inputEvent) {
        // console.log(`on file picked ${event.target.files[0]}`)
        // setSelectedImage(event.target.files[0])
        // uploadImageToPlace()
  
        if (inputEvent.target.files) {
          let imageFile = inputEvent.target.files[0];
          var reader = new FileReader();
          reader.onload = function (e) {
              var img = document.createElement("img");
              img.onload = function (event) {
                  var width = img.width;
                  var height = img.height;
  
                  // Change the resizing logic
                  if (width > height) {
                      if (width > IMAGE_MAX_DIMENSION) {
                          height = height * (IMAGE_MAX_DIMENSION / width);
                          width = IMAGE_MAX_DIMENSION;
                      }
                  } else {
                      if (height > IMAGE_MAX_DIMENSION) {
                          width = width * (IMAGE_MAX_DIMENSION / height);
                          height = IMAGE_MAX_DIMENSION;
                      }
                  }
  
                  var canvas = document.createElement("canvas");
                  canvas.width = width;
                  canvas.height = height;
                  var ctx = canvas.getContext("2d");
                  ctx.drawImage(img, 0, 0, width, height);
  
                  canvas.toBlob((blob) => {
                    let file = new File([blob], "fileName.jpg", { type: "image/jpeg" })
                    setPendingCoverFile(file)
                  }, 'image/jpeg');
  
                  // Show resized image in preview element
                  var dataurl = canvas.toDataURL(imageFile.type)
                  console.log(`dataurl ${dataurl}`)
                  setCoverPhoto(dataurl)
              }
              img.src = e.target.result;
          }
          reader.readAsDataURL(imageFile);
      }
  
    }

    return <div>
        <Helmet><title>Create New Collection | Roambear</title></Helmet>
        {
            currentPage === 0 ? (
                <div>
                    <CoorayAppBar title={"Create Collection"} onBackPressed={onBackPressed} navigationType={NavigationButtonType.Back}/>
                    <div className="font-bold px-4 py-4">The Essentials</div>
                    <div className="form-control w-full px-4">
                        <label className="label">
                            <span className="label-text">Collection name</span>
                        </label>
                        <input type="text" placeholder="e.g. Best cafes in Singapore ..." className="input input-bordered w-full rounded-md" onChange={onTitleChange} value={title}/>
                    </div>
                    <div className="form-control w-full px-4 pt-2">
                        <label className="label">
                            <span className="label-text">Description</span>
                        </label>
                        <input type="text" placeholder="e.g. A list of all the wonderful cafes in 🇸🇬 ..." className="input input-bordered w-full rounded-md" onChange={onDescriptionChange} value={description}/>
                    </div>
                    <div className="form-control w-full px-4 pt-2 ">
                        <label className="label">
                            <span className="label-text">Cover picture</span>
                        </label>
                        <input id="upload-image-input" type="file" accept="image/png, image/jpeg" className="file-input file-input-bordered w-full max-w-xs hidden" onChange={onFileInputChange}/>
                        { coverPhoto &&<div className='pt-2'>
                            <div className="avatar">
                                <div className="w-24 border-4 border-base-content rounded-full">
                                    <img src={coverPhoto} />
                                </div>
                            </div>
                        </div>
                        }
                        <div className='pt-2'>
                        <button className="btn btn-primary normal-case w-auto" onClick={() => {
                            logEvent(analytics, AnalyticsEvent.ADD_CURATION_UPLOAD_COVER_CLICK, {})
                            document.getElementById('upload-image-input').click();
                        }}>Upload Picture</button>
                        </div>
                    </div>
                    <div className="font-bold px-4 pb-4 pt-8">Filters</div>
                    <div className="px-4">
                        {
                            filters.map((filter) => {
                                return <div className='mb-4 rounded-lg bg-base-200 px-4 py-4'>
                                    <div className='flex justify-between items-center'>
                                        <div className='font-bold text-base pb-2'>{filter.label}</div>
                                        <svg className="w-4 h-4 fill-base-content" viewBox="0 -0.5 21 21" version="1.1">
                                            <g stroke="none" strokeWidth="1" fillRule="evenodd">
                                                <g id="Dribbble-Light-Preview" transform="translate(-99.000000, -400.000000)">
                                                    <g id="icons" transform="translate(56.000000, 160.000000)">
                                                        <path d="M61.9,258.010643 L45.1,258.010643 L45.1,242.095788 L53.5,242.095788 L53.5,240.106431 L43,240.106431 L43,260 L64,260 L64,250.053215 L61.9,250.053215 L61.9,258.010643 Z M49.3,249.949769 L59.63095,240 L64,244.114985 L53.3341,254.031929 L49.3,254.031929 L49.3,249.949769 Z" id="edit-[#1479]"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <FilterChipsContainer 
                                        key={filter.id}
                                        title={null}
                                        filterOptions={filter.filterOptions}
                                        onFilterOptionClick={onFilterOptionClick}
                                    />
                                </div>
                            })
                        }
                        <button className="btn btn-secondary" onClick={() => {onAddFilterClick()}}>Add Filter</button>
                    </div>
                    <div className="font-bold px-4 py-4">Visibility</div>
                    <div className="px-4">
                    <div className='mb-4 rounded-lg bg-base-200 px-4 py-4 gap-4 flex flex-col'>
                    <EditCurationVisibilityItemView
                        isSelected={!isCurationPrivate} 
                        title={"Public"} 
                        description={"Visible to anyone with a link to the collection. Anyone can join the collection without an invitation."}
                        titleIcon={<svg className='w-4 h-4' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className='stroke-base-content' d="M16.584 6C15.8124 4.2341 14.0503 3 12 3C9.23858 3 7 5.23858 7 8V10.0288M12 14.5V16.5M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C16.8802 10 17.7202 10 18.362 10.327C18.9265 10.6146 19.3854 11.0735 19.673 11.638C20 12.2798 20 13.1198 20 14.8V16.2C20 17.8802 20 18.7202 19.673 19.362C19.3854 19.9265 18.9265 20.3854 18.362 20.673C17.7202 21 16.8802 21 15.2 21H8.8C7.11984 21 6.27976 21 5.63803 20.673C5.07354 20.3854 4.6146 19.9265 4.32698 19.362C4 18.7202 4 17.8802 4 16.2V14.8C4 13.1198 4 12.2798 4.32698 11.638C4.6146 11.0735 5.07354 10.6146 5.63803 10.327C5.99429 10.1455 6.41168 10.0647 7 10.0288Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>}
                        onClick={() => {
                            setIsCurationPrivate(false)
                        }}
                        />
                        <EditCurationVisibilityItemView
                        isSelected={isCurationPrivate} 
                        title={"Private"} 
                        description={"Visible only to members. A user can only join the collection with a valid invitation link."}
                        titleIcon={
                            <svg className='w-4 h-4' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className='stroke-base-content' d="M12 14.5V16.5M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C15.9474 10 16.5286 10 17 10.0288M7 10.0288C6.41168 10.0647 5.99429 10.1455 5.63803 10.327C5.07354 10.6146 4.6146 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C18.0057 10.1455 17.5883 10.0647 17 10.0288M7 10.0288V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V10.0288" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        }
                        onClick={() => {
                            setIsCurationPrivate(true)
                        }}
                        />
                        </div>
                    </div>
                    <div className='pb-20'></div>
                    <div className='fixed bottom-0 right-4 pb-4 w-full text-center'>
                    <button className="btn btn-success normal-case ml-4" onClick={ onSaveClick }>
                        <svg className="w-6 h-6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path className="stroke-success-content" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.828a2 2 0 0 0-.586-1.414l-1.828-1.828A2 2 0 0 0 16.172 4H15M8 4v4a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V4M8 4h7M7 17v-3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3"/>
                        </svg>
                        Save
                    </button>
                    </div>

                    {loadingText &&
                    <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
                        <div className="loading loading-infinity loading-lg"></div>
                        <div className='font-normal text-base-content mt-2'>{ loadingText }</div>
                    </div>
                    }
                </div>
            ) : (
                (
                    <AddFilterPage analytics={analytics} onFilterSaved={onFilterSaveClick} onBackPressed={onAddFilterBackPressed}/>
                )
            )
        }
    </div>
}
