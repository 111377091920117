import { LazyLoadImage } from "react-lazy-load-image-component";
import GoogleReviewItem from "../googlereview/GoogleReviewItem";
import MainComment from "./MainComment";
import { useState } from "react";
import VotingBlock from "./VotingBlock";
import HighestVoteLabel from "./HighestVoteLabel";

export default function CurationListItem({ placeItem, clickEvent, hoverInEvent, hoverOutEvent, userVote, overallVotes, onPositiveClick, onNegativeClick, isTopVote }) {
    console.log(`user vote for ${placeItem.title} ${placeItem.id} is ${userVote}`)
    const description = placeItem.shortDescription
    const chips = placeItem.filterOptions.map((option) => {
        return <div className="center relative inline-block select-none whitespace-wrap rounded-lg bg-base-100 border-base-content border-dotted border-2 py-2 px-3.5 align-baseline text-sm leading-none text-base-content">{ option.filterLabel }: <span className="font-bold">{option.optionsLabel}</span></div>
    })
    const images = placeItem.images.slice(0, 1).map((image) => {
        return <div className="carousel-item h-full max-w-full w-full">
            <LazyLoadImage className="rounded-md h-full w-full object-cover max-w-full" src={image.url} />
        </div>
    })
    return <div 
        className="px-4 py-4 bg-base-200 cursor-pointer hover:bg-base-300 mb-2 md:grid md:grid-cols-5 md:items-center" 
        onClick={clickEvent}
        onMouseOver={() => {
            console.log(`mouse has entered ${placeItem.id}`)
            hoverInEvent?.(placeItem.id)
        }}
        onMouseOut={() => {
            console.log(`mouse has left ${placeItem.id}`)
            hoverOutEvent?.(placeItem.id)
        }}
    >
        {
            images.length > 0 && 
            <div className="carousel rounded-lg h-60 mt-3 w-full space-x-4 md:mt-0 md:col-span-2">{images}</div>
        }
        <div className={`${images.length > 0 ? "md:col-span-3 md:pl-4" : "md:col-span-5"} md:flex md:flex-col md:justify-between md:h-full`}>
            <div className="top-section w-full py-2">
            {
                isTopVote && <div className="pb-2">
                    <HighestVoteLabel /> 
                </div> 
            }
            {
                placeItem.author && <div className="flex content-center pt-2">
                    <div className="text-xs self-center md:text-sm text-base-content line-clamp-2 opacity-70 pb-1">
                        { `Added by @${placeItem.author.handler}` }
                    </div>
                </div>
            }
            <div className="flex justify-between w-full">
                <div className="text-base-content text-base md:text-lg font-bold text-base-content">{ placeItem.title }</div>
                {
                    placeItem.googlePlace && placeItem.googlePlace.reviewScore && <div className="pl-4">
                        <GoogleReviewItem reviewScore={placeItem.googlePlace.reviewScore}/>
                    </div>
                }
            </div>
            {
                description && <div className="grow text-sm md:text-base self-center text-base-content line-clamp-3 pt-2">
                    { description }
                </div>
            }
            {
                placeItem.mainComment && <div>
                    <MainComment comment={ placeItem.mainComment }/>
                </div>
            }
            {
                placeItem.distance && <div className="flex content-center pt-2">
                    <svg className="w-4 h-4 self-center stroke-base-content" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" strokeWidth="3" fill="none"><path d="M17.94,54.81a.1.1,0,0,1-.14,0c-1-1.11-11.69-13.23-11.69-21.26,0-9.94,6.5-12.24,11.76-12.24,4.84,0,11.06,2.6,11.06,12.24C28.93,41.84,18.87,53.72,17.94,54.81Z"/><circle cx="17.52" cy="31.38" r="4.75"/><path d="M49.58,34.77a.11.11,0,0,1-.15,0c-.87-1-9.19-10.45-9.19-16.74,0-7.84,5.12-9.65,9.27-9.65,3.81,0,8.71,2,8.71,9.65C58.22,24.52,50.4,33.81,49.58,34.77Z"/><circle cx="49.23" cy="17.32" r="3.75"/><path d="M17.87,54.89a28.73,28.73,0,0,0,3.9.89"/><path d="M24.68,56.07c2.79.12,5.85-.28,7.9-2.08,5.8-5.09,2.89-11.25,6.75-14.71a16.72,16.72,0,0,1,4.93-3" stroke-dasharray="7.8 2.92"/><path d="M45.63,35.8a23,23,0,0,1,3.88-.95"/></svg>
                    <div className="text-sm self-center pl-1 md:text-base text-base-content line-clamp-2 opacity-70">
                        { `${Math.round(placeItem.distance * 100) / 100}km from you` }
                    </div>
                </div>
            }
            </div>
            <div className="bottom-section">
            {
                <VotingBlock userVote={userVote} overallVotes={overallVotes} onPositiveClick={onPositiveClick} onNegativeClick={onNegativeClick}/>
            }
            </div>
        </div>
    </div>;
}
