import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { createClient } from "@supabase/supabase-js"
import CoorayAppBar from "../../shared/components/CoorayAppBar"
import { NavigationButtonType } from "../../shared/components/CoorayAppBar"
import SupportTicketRepository from "../../repository/SupportTicketRepository"
import { AnalyticsEvent } from "../../shared/analytics/AnalyticsEvent"
import { logEvent } from "firebase/analytics"
import SupportTicketSuccessPage from "./SupportTicketSuccessPage"

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function SupportTicketPage({ analytics }) {

    const [loadingText, setLoadingText] = useState(null)
    const [toastErrorMessage, setToastErrorMessage] = useState(null)
    const [questionInput, setQuestionInput] = useState("")
    const [emailInput, setEmailInput] = useState("")
    const [userId, setUserId] = useState(null)
    const navigate = useNavigate()

    const [currentPage, setCurrentPage] = useState(0)

    useEffect(() => {
  
        logEvent(analytics, AnalyticsEvent.SUPPORT_QUESTION_VIEW, {})
        async function getLoggedInUser() {
            setLoadingText("Loading ...")
            const {
              data: { user },
            } = await supabase.auth.getUser()
            if (user == null) {
                setUserId(null)
            } else {
                setUserId(user.id)
            }
            setLoadingText(null)
            // if (user == null) {
            //   navigate("/login", {replace: true})
            // } else {
            //   const curation = await curationRepository.getCurations(curationId)
            //   console.log("curation")
            //   console.log(curation)
            //   setTargetCuration(curation.curation)
            //   setLoadingText(null)
            // }

        }
        getLoggedInUser()
      }, [])

    async function saveSupportTicket(content, contact) {
        setLoadingText("Saving ...")
        const supportTicketRepository = new SupportTicketRepository()
        await supportTicketRepository.saveSupportTicket(content, contact)
        setCurrentPage(1)
        setLoadingText(null)
    }

    function onSaveClick(event) {
        if (questionInput && (emailInput || userId)) {
            saveSupportTicket(questionInput, emailInput)
        } else {
            setToastErrorMessage("Please enter your question")
        }
    }

    function onQuestionInputChange(event) {
        setToastErrorMessage("")
        setQuestionInput(event.target.value)
    }

    function onEmailInputChange(event) {
        setToastErrorMessage("")
        setEmailInput(event.target.value)
    }

    function onBackPressed() {
        navigate(`/`)
    }

    return currentPage == 1? (
        <SupportTicketSuccessPage/>
    ) : (<div>
        <CoorayAppBar title={ "Support" } onBackPressed={onBackPressed} navigationType={NavigationButtonType.Back}/>
        <div className="bg-base-200 px-4 py-4">
            <div className="max-w-full text-base-content text-base">Thank you for using Roambear support page. Please submit your question below, and we will get back to you via email within 5 working days.</div>
        </div>
        <div className="form-control w-full px-4">
            <label className="label">
                <span className="label-text">❓ Your question:</span>
            </label>
            <input type="text" placeholder="Question here ..." className="input input-bordered w-full rounded-md" onChange={onQuestionInputChange} value={questionInput}/>
        </div>

        {
            !userId && <div className="form-control w-full px-4 pt-4">
                <label className="label">
                    <span className="label-text">📧 Your email:</span>
                </label>
                <input type="text" placeholder="Provide your email for us to send the answer to ..." className="input input-bordered w-full rounded-md" onChange={onEmailInputChange} value={emailInput}/>
            </div>

        }
        <div className='pb-20'></div>
        <div className='fixed bottom-0 right-4 pb-4 w-full text-center'>
        <button className="btn btn-success normal-case ml-4" onClick={ onSaveClick }>
            <svg className="w-6 h-6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="send"><path className="fill-success-content" d="M21.66,12a2,2,0,0,1-1.14,1.81L5.87,20.75A2.08,2.08,0,0,1,5,21a2,2,0,0,1-1.82-2.82L5.46,13H11a1,1,0,0,0,0-2H5.46L3.18,5.87A2,2,0,0,1,5.86,3.25h0l14.65,6.94A2,2,0,0,1,21.66,12Z"></path></svg>
            Send
        </button>
        </div>

        {toastErrorMessage &&
          <div className="toast toast-bottom toast-center w-full max-w-screen-md">
            <div className="alert alert-info whitespace-normal">
              <span>{toastErrorMessage}</span>
            </div>
          </div>
        }

        {loadingText &&
        <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
            <div className="loading loading-infinity loading-lg"></div>
            <div className='font-normal text-base-content mt-2'>{loadingText}</div>
        </div>
        }
    </div>)
}