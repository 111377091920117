import * as React from 'react';

export default function CoorayAppBar({ title, onBackPressed, navigationType, rightDropdownItems, shouldHideTitleInSmall, shouldHideTitleInBig, endItems }) {
    return (
      <div className={`navbar ease-out ease-in duration-300 ${ shouldHideTitleInSmall ? "bg-transparent" : "bg-base-100" } ${shouldHideTitleInBig ? "md:bg-transparent": "md:bg-base-100"}`}>
        <div className="flex-none">
          { navigationType == NavigationButtonType.Back && <button className="btn btn-square btn-ghost" onClick={() => onBackPressed()}><svg className="w-8 h-8" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path className="fill-base-content" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/><path className="fill-base-content" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/></svg></button> }
          { navigationType == NavigationButtonType.Close && <button className="btn btn-square btn-ghost" onClick={() => onBackPressed()}><svg className="w-8 h-8 fill-base-content" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"/></svg></button> }
          { navigationType == NavigationButtonType.Hamburger && <button className="btn btn-square btn-ghost" onClick={() => onBackPressed()}><svg className="w-8 h-8" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path className="stroke-base-content" d="M5 17H19M5 12H19M5 7H19" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></g></svg></button>}
          { navigationType == NavigationButtonType.Roambear && <button className="btn btn-square btn-ghost" onClick={() => onBackPressed()}><img className="w-8 h-8" src="https://oesctluxvegwtcqckcdg.supabase.co/storage/v1/object/public/cooray-shared-resources/roambear_logo.png"/></button>}
        </div>
        <div className={`flex-auto overflow-hidden`}>
          {
            title && <a className="btn btn-ghost normal-case text-xl max-w-full flex justify-center">
            <div className={`text-ellipsis overflow-hidden font-bold truncate ease-out duration-300 ${ shouldHideTitleInSmall ? "hidden" : "inline-flex" } ${ shouldHideTitleInBig ? "md:hidden" : "md:inline-flex" }`}>{ title }</div>
          </a>
          }
        </div>
        { endItems && <div className="navbar-end">
            { endItems }
          </div>
          
        }
        {
          rightDropdownItems && rightDropdownItems.length > 0 && <div className="dropdown dropdown-end flex-none">
            <button tabIndex={0} className="btn btn-square btn-ghost">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"></path></svg>
            </button>
            <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-72">
              {
                rightDropdownItems.map((item) => {
                  if (item.callback != null) {
                    return <li className='text-base rounded-md' onClick={() => {item.callback()}}><div>{ item.label }</div></li>
                  } else {
                    return <li className='text-base'><a className='rounded-md' href={ item.link }>{ item.label }</a></li>
                  }
                })
              }
            </ul>
          </div>
        }
      </div>
    );
  }

export class AppBarRightDropdownItem {
  constructor(label, link) {
    this.label = label
    this.link = link
  }
}

export const NavigationButtonType = {
  Back: "back",
  Hamburger: "hamburger",
  Close: "close",
  Roambear: "roambear"
}