import { useState, useEffect } from 'react'
import { createClient } from '@supabase/supabase-js'
import { logEvent } from 'firebase/analytics';
import { AnalyticsEvent } from '../../shared/analytics/AnalyticsEvent';
import CurationRepository from '../../repository/CurationRepository';
import { useNavigate, useParams } from "react-router-dom"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Helmet } from "react-helmet"
import CoorayAppBar from "../../shared/components/CoorayAppBar"
import { NavigationButtonType } from "../../shared/components/CoorayAppBar"
import FeedbackButton from "../../shared/components/feedback/FeedbackButton"
import UserRepository from '../../repository/UserRepository';
import Lottie from "lottie-react"
import lottieSuccess from '../../shared/lottie/lottie-success.json';

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function AcceptInvitationPage( { analytics } ) {

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const { invitationLinkId }= useParams()
  const [loadingText, setLoadingText] = useState(null)
  const [isErrorShowing, setIsErrorShowing] = useState(false)
  const [bigErrorText, setBigErrorText] = useState(null)
  const [acceptedInvitationDetails, setAcceptedInvitationDetails] = useState(null)
  const [invitationDetails, setInvitationDetails] = useState(null)
  const navigate = useNavigate()


  useEffect(() => {
  
    const curationRepository = new CurationRepository()
    async function getInvitationDetails() {
        setLoadingText("Loading ...")
        const {
          data: { user },
        } = await supabase.auth.getUser()
        if (user == null) {
          // navigate("/login", {replace: true})
        } else {
          try {
            const invitationDetailsResponse = await curationRepository.getInvitationDetails(invitationLinkId)
            console.log(invitationDetailsResponse)
            setInvitationDetails(invitationDetailsResponse)
            setLoadingText(null)
          } catch (e) {
            setLoadingText(null)
            setBigErrorText("Invitation not found!")
          }
          
        }
    }
    getInvitationDetails()
    logEvent(analytics, AnalyticsEvent.ACCEPT_INVITATION_VIEW, {})
  }, [invitationLinkId])


  async function onAcceptInvitationClick() {
    if (invitationDetails) {
      setLoadingText("Accepting ...")
      const userRepository = new UserRepository()
      const response = await userRepository.acceptInvitationLink(invitationDetails.invitationLinkId)
      console.log(`response is ${response}`)
      setAcceptedInvitationDetails(invitationDetails)
      setLoadingText(null)
    }
  }

  async function onCancelClick() {
    navigate("/")
  }

  async function onCloseClick() {
    navigate("/")
  }
  return <div>
      <Helmet>
        <title>Invitation | Roambear</title>
        <meta property="og:title" content={"Invitation | Roambear"} />
        <meta property="og:description" content={"You are invited to join a collection on Roambear!"} />
        {/* <meta property="og:image" content={imageUrl} /> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        </Helmet>
        <CoorayAppBar title={ "" } onBackPressed={onCloseClick} navigationType={NavigationButtonType.Close}/>
        { !acceptedInvitationDetails && invitationDetails && 
          <div className='grow bg-base-100 flex flex-col place-content-center overflow-y-hidden'>

            <div className="relative h-60 w-full">
              {/* Cover Image */}
              <LazyLoadImage src={"https://media.karousell.com/media/photos/products/2025/2/4/ikea_kitchen_set_1738713451_e565501d_progressive.jpg"} className="w-full h-48 object-cover rounded-md max-w-2xl absolute top-0 left-1/2 transform -translate-x-1/2"/>

              {/* Avatar */}
              <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2">
                    { !invitationDetails.inviterProfilePicture && <div className="avatar placeholder self-center">
                  <div className="bg-neutral text-xl text-neutral-content rounded-full w-24 border-4 border-base-content">
                      <span>{ invitationDetails.inviterName[0] }</span>
                  </div>
                  </div> 
                  }
                  { invitationDetails.inviterProfilePicture && <div className="avatar self-center">
                    <div className="w-24 border-4 border-base-content rounded-full">
                      <LazyLoadImage src={invitationDetails.inviterProfilePicture} />
                    </div>
                  </div>
                  }
              </div>
            </div>
            <div className="pt-2 text-base text-base-content w-full text-center self-center max-w-lg pt-4"><span className='font-bold'>{invitationDetails.inviterName}</span> invited you to the collection</div>
            <div className="pt-2 text-xl text-base-content w-full text-center self-center max-w-lg pt-4"><span className='font-bold'>{invitationDetails.curationName}</span></div>
            { isErrorShowing && <div className="pt-2 text-sm text-error w-full text-center self-center max-w-lg">Invalid invitation code</div> }
            <div className='self-center pt-8 grid grid-cols-2 gap-2'>
                <button className="btn btn-success normal-case" onClick={() => onAcceptInvitationClick() }>
                    <svg className="w-6 h-6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                        <path className="stroke-success-content" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.828a2 2 0 0 0-.586-1.414l-1.828-1.828A2 2 0 0 0 16.172 4H15M8 4v4a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V4M8 4h7M7 17v-3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3"/>
                    </svg>
                    Accept
                </button>
                <button className="btn btn-neutral normal-case" onClick={ () => onCancelClick() }>
                    Cancel
                </button>

            </div>
        </div>
        }

        { acceptedInvitationDetails && 
          <div className='grow bg-base-100 flex flex-col items-center place-content-center overflow-y-hidden'>
              <Lottie className="w-36 h-36" animationData={lottieSuccess} loop={true} />
              <div className="text-base text-base-content w-full text-center self-center max-w-lg">Invitation accepted!</div>
              <div className="pt-2 text-xl text-base-content w-full text-center self-center max-w-lg pt-4">Welcome to <span className='font-bold'>{invitationDetails.curationName}</span></div>
              <div className='self-center pt-8'>
                  <button className="btn btn-neutral normal-case" onClick={ () => onCancelClick() }>
                      Back to Home
                  </button>
              </div>
        </div>
        }

        { bigErrorText && 
          <div className='grow bg-base-100 flex flex-col place-content-center overflow-y-hidden'>

            <div className="pt-2 text-base text-base-content w-full text-center self-center max-w-lg pt-4">{ bigErrorText }</div>
            <div className='self-center pt-8'>
                <button className="btn btn-neutral normal-case" onClick={ () => onCancelClick() }>
                    Back to Home
                </button>

            </div>
        </div>
        }

        {loadingText &&
        <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
            <div className="loading loading-infinity loading-lg"></div>
            <div className='font-normal text-base-content mt-2'>{loadingText}</div>
        </div>
        }
        {
        <FeedbackButton />
        }
        { /iPhone|iPad|iPod/i.test(userAgent) && <div className='fixed bottom-4 right-1/2 translate-x-1/2 bg-transparent text-right md:hidden'>
                <a href="https://apps.apple.com/us/app/roambear-bookmark-places/id6741089867">
                <button className="btn md:btn-lg text-base btn-primary normal-case rounded-full min-w-max">
                <svg className="fill-primary-content w-5 h-5"  viewBox="0 0 512 512">
                <g id="7935ec95c421cee6d86eb22ecd114eed">

                <path d="M248.644,123.476c-5.45-29.71,8.598-60.285,25.516-80.89
                        c18.645-22.735,50.642-40.17,77.986-42.086c4.619,31.149-8.093,61.498-24.826,82.965
                        C309.37,106.527,278.508,124.411,248.644,123.476z M409.034,231.131c8.461-23.606,25.223-44.845,51.227-59.175
                        c-26.278-32.792-63.173-51.83-97.99-51.83c-46.065,0-65.542,21.947-97.538,21.947c-32.96,0-57.965-21.947-97.866-21.947
                        c-39.127,0-80.776,23.848-107.19,64.577c-9.712,15.055-16.291,33.758-19.879,54.59c-9.956,58.439,4.916,134.557,49.279,202.144
                        c21.57,32.796,50.321,69.737,87.881,70.059c33.459,0.327,42.951-21.392,88.246-21.616c45.362-0.258,53.959,21.841,87.372,21.522
                        c37.571-0.317,67.906-41.199,89.476-73.991c15.359-23.532,21.167-35.418,33.11-62.023
                        C414.435,352.487,389.459,285.571,409.034,231.131z">

                </path>

                </g>

                </svg> Get the iPhone App
                </button>
                </a>
                </div>
            }
            { /Android/i.test(userAgent) && <div className='fixed bottom-4 right-1/2 translate-x-1/2 bg-transparent text-right md:hidden'>
                <a href="play.google.com/store/apps/details?id=com.roambear.roambearandroid">
                <button className="btn md:btn-lg text-base btn-primary normal-case rounded-full min-w-max" onClick={() => { }}>
                <svg className="fill-primary-content w-5 h-5" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.35 12.653l2.496-4.323c0.044-0.074 0.070-0.164 0.070-0.26 0-0.287-0.232-0.519-0.519-0.519-0.191 0-0.358 0.103-0.448 0.257l-0.001 0.002-2.527 4.377c-1.887-0.867-4.094-1.373-6.419-1.373s-4.532 0.506-6.517 1.413l0.098-0.040-2.527-4.378c-0.091-0.156-0.259-0.26-0.45-0.26-0.287 0-0.519 0.232-0.519 0.519 0 0.096 0.026 0.185 0.071 0.262l-0.001-0.002 2.496 4.323c-4.286 2.367-7.236 6.697-7.643 11.744l-0.003 0.052h29.991c-0.41-5.099-3.36-9.429-7.57-11.758l-0.076-0.038zM9.098 20.176c-0 0-0 0-0 0-0.69 0-1.249-0.559-1.249-1.249s0.559-1.249 1.249-1.249c0.69 0 1.249 0.559 1.249 1.249v0c-0.001 0.689-0.559 1.248-1.249 1.249h-0zM22.902 20.176c-0 0-0 0-0 0-0.69 0-1.249-0.559-1.249-1.249s0.559-1.249 1.249-1.249c0.69 0 1.249 0.559 1.249 1.249v0c-0.001 0.689-0.559 1.248-1.249 1.249h-0z"></path>
                </svg><div>Get the Android App</div>
                </button>
                </a>
                </div>
            }
    </div>
}