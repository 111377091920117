import axios from "axios";
import { getExplorePlacesUrl } from "./ServiceUrls";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default class ExploreRepository {
    async getExplorePlaces(offset, seed, tags, googlePlaceId) {
        const session = await supabase.auth.getSession()
        try {
            const response = await axios.get(
                getExplorePlacesUrl(offset, seed, tags, googlePlaceId),
                {
                    headers: {
                        'Authorization': `Bearer ${session.data.session.access_token}`
                    }
                }
            );
            return response.data
        } catch (err) {
            return err.response.data
        }
    }

}
