import { useState } from 'react';
import Sheet from 'react-modal-sheet';
import ExploreFilterModal from '../explorefiltermodal/ExploreFilterModal';
import CoorayAppBar, { NavigationButtonType } from '../CoorayAppBar';
import ExploreListSearchInput from './ExploreListSearchInput';

export default function ExploreListFilterBar({ appliedPlace, selectedTags, onTagPress, onTagsApplied, onLocationApplied }) {
    const [isExplorePlaceFilterModelOpen, setIsExplorePlaceFilterModelOpen] = useState(false)
    const [isShowingSearchLocationInput, setIsShowingSearchLocationInput] = useState(false)
    const [selectedPlace, setSelectedPlace] = useState(appliedPlace)
    const filters = {
        "nonFood": [
            {
            "tag": "outdoor",
            "cleanLabel": "Outdoor",
            "displayLabel": "🌿 Outdoor"
            },
            {
            "tag": "scenic",
            "cleanLabel": "Scenic",
            "displayLabel": "🌅 Scenic"
            },
            {
            "tag": "historic_cultural",
            "cleanLabel": "Historic & Cultural",
            "displayLabel": "🏛️ Historic & Cultural"
            },
            {
            "tag": "date_night",
            "cleanLabel": "Date Night",
            "displayLabel": "💕 Date Night"
            },
            {
            "tag": "dog_friendly",
            "cleanLabel": "Dog Friendly",
            "displayLabel": "🐶 Dog Friendly"
            },
            {
            "tag": "kid_friendly",
            "cleanLabel": "Kid Friendly",
            "displayLabel": "👶🏻 Kid Friendly"
            },
            {
            "tag": "live_music",
            "cleanLabel": "Live Music & Entertainment",
            "displayLabel": "🎶 Live Music & Entertainment"
            },
            {
            "tag": "art_creativity",
            "cleanLabel": "Art & Creativity",
            "displayLabel": "🎨 Art & Creativity"
            },
            {
            "tag": "shopping_markets",
            "cleanLabel": "Shopping & Markets",
            "displayLabel": "🛍️ Shopping & Markets"
            },
            {
            "tag": "wellness_relaxation",
            "cleanLabel": "Wellness & Relaxation",
            "displayLabel": "🧘 Wellness & Relaxation"
            },
            {
            "tag": "hidden_gems",
            "cleanLabel": "Hidden Gems",
            "displayLabel": "🔍 Hidden Gems"
            }
        ],
        "food": [
            {
            "tag": "brunch_spots",
            "cleanLabel": "Brunch Spots",
            "displayLabel": "🍳 Brunch Spots"
            },
            {
            "tag": "cafe",
            "cleanLabel": "Cafe",
            "displayLabel": "☕ Cafe"
            },
            {
            "tag": "craft_beer",
            "cleanLabel": "Craft Beer",
            "displayLabel": "🍻 Craft Beer"
            },
            {
            "tag": "cocktails",
            "cleanLabel": "Cocktails",
            "displayLabel": "🍸 Cocktails"
            },
            {
            "tag": "cheap_eats",
            "cleanLabel": "Cheap Eats",
            "displayLabel": "💰 Cheap Eats"
            },
            {
            "tag": "fine_dining",
            "cleanLabel": "Fine Dining",
            "displayLabel": "🍽️ Fine Dining"
            },
            {
            "tag": "street_food",
            "cleanLabel": "Street Food",
            "displayLabel": "🌮 Street Food"
            },
            {
            "tag": "vegetarian",
            "cleanLabel": "Vegetarian",
            "displayLabel": "🥕 Vegetarian"
            },
            {
            "tag": "healthy",
            "cleanLabel": "Healthy",
            "displayLabel": "🥗 Healthy"
            },
            {
            "tag": "halal",
            "cleanLabel": "Halal",
            "displayLabel": "🕌 Halal"
            },
            {
            "tag": "desserts",
            "cleanLabel": "Desserts",
            "displayLabel": "🍰 Desserts"
            },
            {
            "tag": "late_night",
            "cleanLabel": "Late-Night Bites",
            "displayLabel": "🌙 Late-Night Bites"
            },
            {
            "tag": "bakery",
            "cleanLabel": "Bakery",
            "displayLabel": "🥐 Bakery"
            },
            {
            "tag": "scenic",
            "cleanLabel": "Scenic",
            "displayLabel": "🌅 Scenic"
            },
            {
            "tag": "date_night",
            "cleanLabel": "Date Night",
            "displayLabel": "💕 Date Night"
            },
            {
            "tag": "dog_friendly",
            "cleanLabel": "Dog Friendly",
            "displayLabel": "🐶 Dog Friendly"
            },
            {
            "tag": "kid_friendly",
            "cleanLabel": "Kid Friendly",
            "displayLabel": "👶🏻 Kid Friendly"
            }
        ]
    }
    return (
        <div className="bg-base-100 py-4">
            <div direction={"row"} spacing={1} className='max-w-full overflow-scroll whitespace-nowrap space-x-2 mt-0 no-scrollbar flex'>
            <button className={`group btn btn-sm md:btn-md normal-case font-bold ml-4 text-sm md:text-base rounded-full btn-neutral`} onClick={() => { setIsExplorePlaceFilterModelOpen(true) }}>
                🎛️ Filters { selectedTags.length > 0 ? <div className="badge badge-secondary text-sm md:text-base">1</div> : <div></div> }
            </button>
            <button className={`group btn btn-sm md:btn-md normal-case font-bold ml-4 text-sm md:text-base rounded-full btn-neutral`} onClick={() => { setIsShowingSearchLocationInput(true) }}>
                📍 Location { selectedPlace ? <div className="badge badge-secondary text-sm md:text-base">1</div> : <div></div> }
            </button>
            {/* {
                filters.food.map((foodItem) => 
                    <button 
                    className={`group snap-start btn btn-sm normal-case font-normal text-sm rounded-full ${ selectedTags.includes(foodItem.tag) ? "btn-primary" : "btn-neutral" }`}
                    onClick={() => { onTagPress(foodItem.tag) }}
                    >
                        { foodItem.displayLabel }
                    </button>
                )
            } */}
            <div className='w-2'></div>
            </div>

            {
                <ExploreFilterModal
                    isFilterModalOpen={isExplorePlaceFilterModelOpen}
                    filters={filters.food}
                    selectedFilterOptions={selectedTags}
                    existingDistance={0}
                    onApplyClick={(options) => {
                        onTagsApplied(options)
                    }}
                    onFilterModalDismiss={() => { setIsExplorePlaceFilterModelOpen(false) }}
                />
            }

            {
            <Sheet 
            isOpen={isShowingSearchLocationInput} 
            onClose={() => {setIsShowingSearchLocationInput(false)}} 
            onOpenEnd={() => {}} 
            detent="full-height"
            >
                <Sheet.Container>
                <Sheet.Header />
                <Sheet.Content>
                <CoorayAppBar 
                title={ "Enter location" }
                onBackPressed={() => {
                    setIsShowingSearchLocationInput(false)  
                }}
                navigationType={NavigationButtonType.Close}
                />
                <Sheet.Scroller draggableAt="both">
                    <div>
                    <ExploreListSearchInput appliedPlace={selectedPlace} onPlaceSelected={(place) => { setSelectedPlace(place) }}/>
                    <div className="modal-action absolute bottom-0 right-0 bg-base-100 w-full px-6 py-4">
                        <button className="btn" onClick={
                            () => {
                                onLocationApplied(null)
                                setSelectedPlace(null)
                                setIsShowingSearchLocationInput(false)
                            }
                        }>Clear</button>
                        <button className="btn btn-success text-success-content mr-2" onClick={
                            () => {
                                if (selectedPlace) {
                                    onLocationApplied(selectedPlace)
                                    setIsShowingSearchLocationInput(false)
                                }
                            }
                        } >Apply
                        </button>
                        <button className="btn btn-ghost" onClick={
                            () => {
                                setIsShowingSearchLocationInput(false)
                            }
                            }>Close</button>
                    </div>
                    </div>
                    
                </Sheet.Scroller>
                </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop onTap={(_) => {
                    setIsShowingSearchLocationInput(false)
                }}/>
            </Sheet>
            }

        </div>
    );
  }
