import { useState, useEffect } from 'react'
import CoorayAppBar, { NavigationButtonType } from "../../shared/components/CoorayAppBar"
import FilterOptionChip from '../../shared/components/filteroptionchip/FilterOptionChip'
import FilterRepository from '../../repository/FilterRepository'
import { createClient } from '@supabase/supabase-js'
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import FeedbackButton from '../../shared/components/feedback/FeedbackButton'

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function EditFilterPage({curationId, editingFilter, onFilterSaved, onBackPressed}) {
    const [options, setOptions] = useState(editingFilter.filterOptions)
    const [optionInput, setOptionInput] = useState("")
    const [filterNameInput, setFilterNameInput] = useState(editingFilter.label)
    const [isSaving, setIsSaving] = useState(false)
    const navigate = useNavigate()


    async function saveFilter(filterName, filterOptions) {
        setIsSaving(true)
        const session = await supabase.auth.getSession()
        if (session.data.session == null) {
            navigate("/login", {replace: true})
        } else {
            const filterRepository = new FilterRepository()
            const updatedFilter = await filterRepository.updateFilterById(
                curationId,
                editingFilter.id,
                filterName,
                filterOptions
            )
            onFilterSaved(updatedFilter.filter)
        }
    }

    function onSaveClick() {
        console.log(`on save click ${filterNameInput} ${JSON.stringify(options)}`)
        if (filterNameInput && options.length > 0) {
            saveFilter(
                filterNameInput,
                options
            )
        }
    }

    function onAddFilterOptionClick() {
        console.log(`add filter option click ${optionInput}`)
        if (optionInput) {
            const updatedOptions = options.concat([{label: optionInput}])
            setOptions(updatedOptions)
            setOptionInput("")
        }
    }

    function handleOptionInputChange(event) {
        console.log(`option input change ${event.target.value}`)
        setOptionInput(event.target.value)
    }

    function handleFilterNameInputChange(event) {
        console.log(`option input change ${event.target.value}`)
        setFilterNameInput(event.target.value)
    }

    function onFilterOptionEndIconClick(index) {
        console.log(`end icon click ${index}`)
        setOptions(
            options.filter((_, optionIndex) => optionIndex != index)
        )
    }

    return <div>
        <Helmet><title>Editing Filter</title></Helmet>
        <CoorayAppBar title={"Edit Filter"} onBackPressed={onBackPressed} navigationType={NavigationButtonType.Back}/>
        <div className="form-control w-full px-4">
            <label className="label">
                <span className="label-text">Filter name</span>
            </label>
            <input type="text" placeholder="e.g. Cuisine" className="input input-bordered w-full rounded-md" onChange={handleFilterNameInputChange} value={filterNameInput}/>
        </div>
        <div className="font-bold px-4 pt-8 pb-1">Options</div>
        {
            options.length > 0 && <div className="px-4 py-4 space-y-2 grid grid-cols-1">
                {
                    options.map((option, index) => {
                        return <div>
                            <FilterOptionChip 
                                key={index}
                                chipId={index}
                                label={option.label}
                                isSelected={false}
                                endIcon={
                                    <svg className="w-5 h-5 fill-base-content group-hover:fill-base-100" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"/>
                                    </svg>
                                }
                                onFilterOptionClick={() => {}}
                                onEndIconClick={
                                    (_) => {
                                        onFilterOptionEndIconClick(index)
                                    }
                                }
                            />
                        </div>
                    })
                }
            </div>
        }
        <div className="form-control w-full px-4">
                <label className="label">
                    <span className="label-text">Option name</span>
                </label>
                <input type="text" placeholder="e.g. Fusion" className="input input-bordered w-full rounded-md" onChange={handleOptionInputChange} value={optionInput} />
        </div>
        <button className="btn btn-secondary ml-4 mt-4 mb-32" onClick={() => onAddFilterOptionClick()}>Add Option</button>
        <div className='fixed bottom-0 right-4 pb-4 w-full text-center'>
          <button className="btn btn-primary normal-case ml-4" onClick={() => onSaveClick()}>Save</button>
        </div>
        {isSaving &&
            <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
                <div className="loading loading-infinity loading-lg"></div>
                <div className='font-normal text-base-content mt-2'>Saving ...</div>
            </div>
        }
        {
          <FeedbackButton />
        }
    </div>
}
