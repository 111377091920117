import HomeCurationItem from "../../shared/components/curation/HomeCurationItem";
import { createClient } from "@supabase/supabase-js";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CurationRepository from "../../repository/CurationRepository";
import PendingInvitations from "../../shared/components/pendinginvitations/PendingInvitations";
import UserRepository from "../../repository/UserRepository";
import FilterOptionChip from "../../shared/components/filteroptionchip/FilterOptionChip";
import { logEvent } from "firebase/analytics";
import { AnalyticsEvent } from "../../shared/analytics/AnalyticsEvent";
import { Helmet } from "react-helmet";
import { useYourCurationsContext } from "./YourCurationsContext";

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function YourCurationsPage({ analytics, onProfileLoaded, onTitleChange }) {

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const [loadingText, setLoadingText] = useState("Loading ...")
    const {combinedCurations, setCombinedCurations} = useYourCurationsContext()
    const [pendingInvitations, setPendingInvitations] = useState([])
    const [isInvitationSectionSaving, setIsInvitationSectionSaving] = useState(false)
    const [isFirstLoading, setIsFirstLoading] = useState(true)
    const navigate = useNavigate()

    async function getUserCurations() {
        const curationRepository = new CurationRepository()
        setLoadingText("Loading ...")
        const curationResponse = await curationRepository.getUserCurations()
        console.log(curationResponse)

        setCombinedCurations(
            curationResponse.combinedCurations.map((curation) => {

                let placeCount
                let memberCount
                if (curationResponse.curationPlaceCount[curation.id]) {
                    placeCount = curationResponse.curationPlaceCount[curation.id]
                } else {
                    placeCount = 0
                }
                if (curationResponse.curationMemberCount[curation.id]) {
                    memberCount = curationResponse.curationMemberCount[curation.id]
                } else {
                    memberCount = 0
                }
                return {
                    ...curation, placeCount: placeCount, memberCount: memberCount, author: curationResponse.userDetails[curation.authorUserId]
                }
            })
        )
        setLoadingText(null)
        setIsFirstLoading(false)
        onTitleChange("Your Collections")
      }

    useEffect(() => {
  
        async function getUserProfile() {
          const {
            data: { user },
          } = await supabase.auth.getUser()
          if (user == null) {
            navigate("/login", {replace: true})
          } else {
            const userRepository = new UserRepository()
            try {
                const profileResponse = await userRepository.getUserProfile()
                console.log(profileResponse)
                onProfileLoaded(profileResponse)
            } catch(e) {
                console.log(`get profile error ${e.response.status}`)
                navigate("/signup/completion", {replace: true})
            }
          }
        }
        // getUserProfile()

        async function getPendingInvitations() {
            try {
                const userRepository = new UserRepository()
                const pendingInvitationResponse = await userRepository.getUserPendingInvitations()
                console.log(`${JSON.stringify(pendingInvitationResponse)}`)
                setPendingInvitations(
                    pendingInvitationResponse.invitations
                )
            } catch(e) {

            }
        }
        console.log(`refetching everything`)
        Promise.all(
            [
                getPendingInvitations(),
                getUserCurations(),
                getUserProfile()
            ]
        )
    }, [])

    async function saveAcceptInvitation(targetInvitation) {
        const userRepository = new UserRepository()
        setIsInvitationSectionSaving(true)
        await userRepository.acceptInvitation(targetInvitation.id)
        setIsInvitationSectionSaving(false)
        setPendingInvitations(
            pendingInvitations.map((invitation) => {
                if (invitation.id == targetInvitation.id) {
                    return {
                        ...invitation, isAccepted: true
                    }
                } else {
                    return invitation
                }
            })
        )
        getUserCurations()
    }

    async function saveDeclineInvitation(targetInvitation) {
        const userRepository = new UserRepository()
        setIsInvitationSectionSaving(true)
        await userRepository.declineInvitation(targetInvitation.id)
        setIsInvitationSectionSaving(false)
        setPendingInvitations(
            pendingInvitations.filter((invitation) => invitation.id != targetInvitation.id)
        )
    }

    function onDismissInvitationClick(targetInvitation) {
        console.log(`dismiss invitation ${JSON.stringify(targetInvitation)}`)
        setPendingInvitations(
            pendingInvitations.filter((invitation) => invitation.id != targetInvitation.id)
        )
    }
    function onAcceptInvitationClick(targetInvitation) {
        saveAcceptInvitation(targetInvitation)
    }

    function onDeclineInvitationClick(targetInvitation) {
        saveDeclineInvitation(targetInvitation)
    }

    return (
        <div className="bg-base-100">
            <Helmet>
                {
                    isFirstLoading && <title>Roambear | Mark your spots</title>
                }
                {
                    !isFirstLoading && <title>Your Collections | Roambear</title>
                }
            </Helmet>
            {pendingInvitations.length > 0 && <div className="pb-2">
                <PendingInvitations
                    pendingInvitations={pendingInvitations}
                    onDismissInvitationClick={onDismissInvitationClick}
                    onAcceptInvitationClick={onAcceptInvitationClick}
                    onDeclineInvitationClick={onDeclineInvitationClick}
                    isSaving={isInvitationSectionSaving}
                />
            </div>}
            <div className="grid grid-cols-3 md:grid-cols-6 gap-2 md:px-4 pb-20 items-stretch">
                {
                    combinedCurations.map((curation) => {
                        const emojiText = curation.emoji != null ? `${curation.emoji} ` : ""
                        return <div className="col-span-3" key={curation.id}>
                            <HomeCurationItem title={`${curation.title}`} 
                            url={`/collections/${curation.id}`}
                            description={curation.description}
                            numberOfPlaces={curation.placeCount}
                            numberOfContributors={curation.memberCount}
                            imageUrl={curation.coverPhoto} 
                            emoji={curation.emoji}
                            author={curation.author}
                            />
                        </div>
                    })
                }
                {!loadingText && !isFirstLoading && combinedCurations.length == 0 &&
                <div className='col-span-3 md:col-span-6 flex flex-col items-center justify-center pt-4'>
                    <div>
                    <svg className="w-16 h-16 fill-base-content" version="1.1" viewBox="0 0 310.883 310.883">
                        <path d="M299.459,174.267c0,0-16.433-71.773-16.707-72.356c-3.429-10.694-17.078-19.279-40.725-25.565
                            c-23.243-6.181-53.993-9.584-86.586-9.584c-32.592,0-63.343,3.403-86.586,9.584c-23.657,6.29-37.308,14.879-40.729,25.58
                            c-0.272,0.578-16.702,72.342-16.702,72.342C4.778,176.576,0,182.879,0,190.312c0,18.79,17.893,33.075,53.18,42.458
                            c27.533,7.32,63.85,11.353,102.261,11.353c0.002,0,0.004,0,0.006,0c38.41,0,74.724-4.031,102.255-11.352
                            c35.287-9.383,53.18-23.668,53.18-42.459C310.883,182.879,306.105,176.576,299.459,174.267z M211.452,189.198
                            c0,7.987-6.498,14.486-14.485,14.486c-7.755,0-14.107-6.124-14.471-13.79h-54.11c-0.365,7.666-6.715,13.79-14.469,13.79
                            c-7.988,0-14.486-6.499-14.486-14.486c0-3.783,1.458-7.232,3.842-9.815c-2.384-2.583-3.842-6.032-3.842-9.815
                            c0-7.987,6.499-14.486,14.486-14.486c7.754,0,14.104,6.124,14.469,13.79h54.11c0.364-7.666,6.716-13.79,14.471-13.79
                            c7.987,0,14.485,6.499,14.485,14.486c0,3.783-1.458,7.232-3.842,9.815C209.994,181.966,211.452,185.415,211.452,189.198z
                            M235.357,120c-21.545,5.448-49.926,8.449-79.916,8.449c-29.99,0-58.371-3.001-79.916-8.449
                            c-20.722-5.24-28.012-10.998-29.796-13.382c1.8-2.425,9.104-8.177,29.8-13.409c21.544-5.448,49.924-8.448,79.912-8.448
                            c29.987,0,58.367,3,79.911,8.448c20.654,5.223,27.97,10.961,29.789,13.395C263.329,109.033,256.023,114.773,235.357,120z"/>
                        </svg>
                    </div>
                    <div className='font-normal text-base-content mt-2'>Nothing here.</div>
                    <div className='font-normal text-base-content mt-2'>Press <span class="inline-flex items-baseline"><div className="btn btn-primary btn-sm mx-2 normal-case" onClick={() => {navigate("/collections/add")}}>+ Create Collection</div></span> to create a new one!</div>
                </div>
                }
                { /iPhone|iPad|iPod/i.test(userAgent) && <div className='fixed bottom-[5rem] right-1/2 translate-x-1/2 bg-transparent text-right md:hidden'>
                    <a href="https://apps.apple.com/us/app/roambear-bookmark-places/id6741089867">
                    <button className="btn md:btn-lg text-base btn-primary normal-case rounded-full min-w-max">
                    <svg className="fill-primary-content w-5 h-5"  viewBox="0 0 512 512">
                    <g id="7935ec95c421cee6d86eb22ecd114eed">

                    <path d="M248.644,123.476c-5.45-29.71,8.598-60.285,25.516-80.89
                            c18.645-22.735,50.642-40.17,77.986-42.086c4.619,31.149-8.093,61.498-24.826,82.965
                            C309.37,106.527,278.508,124.411,248.644,123.476z M409.034,231.131c8.461-23.606,25.223-44.845,51.227-59.175
                            c-26.278-32.792-63.173-51.83-97.99-51.83c-46.065,0-65.542,21.947-97.538,21.947c-32.96,0-57.965-21.947-97.866-21.947
                            c-39.127,0-80.776,23.848-107.19,64.577c-9.712,15.055-16.291,33.758-19.879,54.59c-9.956,58.439,4.916,134.557,49.279,202.144
                            c21.57,32.796,50.321,69.737,87.881,70.059c33.459,0.327,42.951-21.392,88.246-21.616c45.362-0.258,53.959,21.841,87.372,21.522
                            c37.571-0.317,67.906-41.199,89.476-73.991c15.359-23.532,21.167-35.418,33.11-62.023
                            C414.435,352.487,389.459,285.571,409.034,231.131z">

                    </path>

                    </g>

                    </svg> Get the iPhone App
                    </button>
                    </a>
                    </div>
                }
                { /Android/i.test(userAgent) && <div className='fixed bottom-[5rem] right-1/2 translate-x-1/2 bg-transparent text-right md:hidden'>
                    <a href="play.google.com/store/apps/details?id=com.roambear.roambearandroid">
                    <button className="btn md:btn-lg text-base btn-primary normal-case rounded-full min-w-max" onClick={() => { }}>
                    <svg className="fill-primary-content w-5 h-5" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.35 12.653l2.496-4.323c0.044-0.074 0.070-0.164 0.070-0.26 0-0.287-0.232-0.519-0.519-0.519-0.191 0-0.358 0.103-0.448 0.257l-0.001 0.002-2.527 4.377c-1.887-0.867-4.094-1.373-6.419-1.373s-4.532 0.506-6.517 1.413l0.098-0.040-2.527-4.378c-0.091-0.156-0.259-0.26-0.45-0.26-0.287 0-0.519 0.232-0.519 0.519 0 0.096 0.026 0.185 0.071 0.262l-0.001-0.002 2.496 4.323c-4.286 2.367-7.236 6.697-7.643 11.744l-0.003 0.052h29.991c-0.41-5.099-3.36-9.429-7.57-11.758l-0.076-0.038zM9.098 20.176c-0 0-0 0-0 0-0.69 0-1.249-0.559-1.249-1.249s0.559-1.249 1.249-1.249c0.69 0 1.249 0.559 1.249 1.249v0c-0.001 0.689-0.559 1.248-1.249 1.249h-0zM22.902 20.176c-0 0-0 0-0 0-0.69 0-1.249-0.559-1.249-1.249s0.559-1.249 1.249-1.249c0.69 0 1.249 0.559 1.249 1.249v0c-0.001 0.689-0.559 1.248-1.249 1.249h-0z"></path>
                    </svg><div>Get the Android App</div>
                    </button>
                    </a>
                    </div>
                }
                {loadingText &&
                <div className='col-span-3 md:col-span-6 flex flex-col items-center justify-center pt-4'>
                    <div className="loading loading-infinity loading-lg"></div>
                    <div className='font-normal text-base-content mt-2'>{loadingText}</div>
                </div>
                }
            </div>
        </div>
    )
}
