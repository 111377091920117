import { useState, useEffect } from "react"
import CurationRepository from "../../repository/CurationRepository"
import { useNavigate, useParams } from "react-router-dom"
import { createClient } from "@supabase/supabase-js"
import CoorayAppBar from "../../shared/components/CoorayAppBar"
import { NavigationButtonType } from "../../shared/components/CoorayAppBar"
import MemberItem from "./MemberItem"
import PendingMemberItem from "./PendingMemberItem"
import { logEvent } from "firebase/analytics"
import { AnalyticsEvent } from "../../shared/analytics/AnalyticsEvent"
import { Helmet } from "react-helmet"
import { useCurationMembersContext } from "../curation/CurationMembersContext"
import FeedbackButton from "../../shared/components/feedback/FeedbackButton"
import GeneratedInvitationLinkModal from "../../shared/components/invitationlinkmodal/GeneratedInvitationLinkModal"

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function MembersPage({ analytics }) {
    const navigate = useNavigate()
    const { curationId }= useParams()
    const {curationMemberResponse, _} = useCurationMembersContext()
    const [loadingText, setLoadingText] = useState(null)
    const [generatedInvitationLink, setGeneratedInvitationLink] = useState(null)
    const [generatedInvitationTitle, setGeneratedInvitationTitle] = useState(null)
    const [members, setMembers] = useState(
      curationMemberResponse && curationMemberResponse.curationId == curationId ? curationMemberResponse.members.map((member) => {
        return {
          ...member, isOwner: curationMemberResponse.membership[member.authUid] == "OWNER"
        }
      }) : []
    )
    const [pendingMembers, setPendingMembers] = useState(
      curationMemberResponse && curationMemberResponse.curationId == curationId ? curationMemberResponse.pendingAcceptance : []
    )

    useEffect(() => {
  
        const curationRepository = new CurationRepository()
        async function getMembers(curationId) {
            setLoadingText("Loading ...")
            const {
              data: { user },
            } = await supabase.auth.getUser()
            if (user == null) {
              navigate("/login", {replace: true})
            } else {
              const memberResponse = await curationRepository.getCurationMembers(curationId)
              console.log(memberResponse)
              setMembers(
                memberResponse.members.map((member) => {
                  return {
                    ...member, isOwner: memberResponse.membership[member.authUid] == "OWNER"
                  }
                })
              )
              setPendingMembers(
                memberResponse.pendingAcceptance
              )
              setLoadingText(null)
            }
        }
        if (!curationMemberResponse || curationMemberResponse.curationId != curationId) {
          getMembers(curationId)
        }
      }, [curationId])

    async function generateInvitationLink(curationId) {
        setLoadingText("Generating invitation link ...")
        const curationRepository = new CurationRepository()
        const response = await curationRepository.generateInvitationLink(curationId)
        console.log(JSON.stringify(response))
        setGeneratedInvitationLink(response.invitationLink)
        setGeneratedInvitationTitle(response.invitationTitle)
        setLoadingText(null)
    }

    function onBackPressed() {
      window.history.back()
    }

    const shareContent = async (title, url) => {
      if (navigator.share) {
        try {
          await navigator.share({
            title: "Roambear",
            text: title,
            url: url,
          });
        } catch (error) {
          console.error("Error sharing:", error);
        }
      } else {
        alert("Sharing not supported on this device.");
      }
    };

    const copyToClipboard = async (text) => {
      try {
        await navigator.clipboard.writeText(text);
        console.log("Copied to clipboard:", text);
      } catch (err) {
        console.error("Failed to copy:", err);
      }
    };    

    return <div className="bg-base-100 min-h-[100vh] max-h-screen overflow-y-scroll">
        <Helmet><title>Members</title></Helmet>
        <CoorayAppBar title={ "Members" } onBackPressed={onBackPressed} navigationType={NavigationButtonType.Back}/>
        <div className="px-4 gap-y-2 flex flex-col">
          <button className="btn btn-primary normal-case w-full text-base md:w-auto mb-2" onClick={() => {
            generateInvitationLink(curationId)
            logEvent(analytics, AnalyticsEvent.INVITE_MEMBER_CLICK, {})
          } }>
              <svg className="w-6 h-6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="send"><path className="fill-primary-content" d="M21.66,12a2,2,0,0,1-1.14,1.81L5.87,20.75A2.08,2.08,0,0,1,5,21a2,2,0,0,1-1.82-2.82L5.46,13H11a1,1,0,0,0,0-2H5.46L3.18,5.87A2,2,0,0,1,5.86,3.25h0l14.65,6.94A2,2,0,0,1,21.66,12Z"></path></svg>
              Invite member
          </button>
          {
            members.map((member) => {
              return MemberItem(
                member, () => {
                  navigate(`/profile/${member.handler}`)
                }
              )
            })
          }
          {
            pendingMembers.map((pendingMember) => {
              return PendingMemberItem(pendingMember)
            })
          }
        </div>

        {loadingText &&
        <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
            <div className="loading loading-infinity loading-lg"></div>
            <div className='font-normal text-base-content mt-2'>{loadingText}</div>
        </div>
        }
        {
          <FeedbackButton />
        }
        <GeneratedInvitationLinkModal 
          isInvitationLinkModalOpen={generatedInvitationLink != null}
          url={generatedInvitationLink}
          onGeneratedInvitationLinkDismiss={() => {
            setGeneratedInvitationLink(null)
          }}
          onShareLinkClick={() => {
              shareContent(generatedInvitationTitle, generatedInvitationLink)
          }}
          onCopyLinkClick={() => {
              copyToClipboard(generatedInvitationLink)
          }}
        />
    </div>
}