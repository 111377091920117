import React, { createContext, useState, useContext } from 'react';

const CurationPlacesContext = createContext();

export const useCurationPlacesContext = () => {
  return useContext(CurationPlacesContext);
};

export const CurationPlacesProvider = ({ children }) => {
  const [placesData, setPlacesData] = useState({
    selectedSortType: "recommended",
    selectedFilterDistance: null,
    places: [],
    totalPlaceCount: 0,
    selectedFilterOptions: [],
    selectedVisitOptions: {
      visited: false,
      nonVisited: false
    },
    selectedSearchInput: "",
    isPlaceListSneakPeek: false
  });

  return (
    <CurationPlacesContext.Provider value={{ placesData, setPlacesData }}>
      {children}
    </CurationPlacesContext.Provider>
  );
};